import { DataGrid, DataGridProps, GridValidRowModel } from '@mui/x-data-grid';
import NoData from 'components/NoData';
import { useCallback } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const { theme: dakotaTheme } = resolveConfig(tailwindConfig);

export const MuiGridWrapper = <T extends GridValidRowModel>(
  props: DataGridProps<T>,
) => {
  const noData = useCallback(() => <NoData />, []);

  return (
    <DataGrid
      {...props}
      density={props.density ?? 'comfortable'}
      disableColumnFilter={props.disableColumnFilter ?? true}
      disableColumnMenu={props.disableColumnMenu ?? true}
      disableColumnSelector={props.disableColumnSelector ?? true}
      disableColumnSorting={props.disableColumnSorting ?? true}
      disableDensitySelector={props.disableDensitySelector ?? true}
      disableRowSelectionOnClick={props.disableRowSelectionOnClick ?? true}
      initialState={
        props.initialState ?? {
          pagination: {
            paginationModel: { pageSize: 10 },
          },
        }
      }
      loading={props.loading}
      pageSizeOptions={props.pageSizeOptions ?? [10, 20, 50]}
      slotProps={{
        loadingOverlay: {
          noRowsVariant: 'linear-progress',
          variant: 'linear-progress',
        },
      }}
      slots={{ noRowsOverlay: noData }}
      sx={{
        ...props.sx,
        /** Allow `NoData` to be visible with padding above and below. */
        '--DataGrid-overlayHeight': '200px',
        /* Hide vertical separator that comes after the last column header. */
        '& .MuiDataGrid-columnHeader--last .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        /* Use a light gray color as a background for header and footer */
        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-footerContainer, & .MuiDataGrid-topContainer .MuiDataGrid-filler':
          {
            backgroundColor: dakotaTheme.colors.gray[100],
          },
        /* Make cell focus state consistent with the rest of our components. */
        '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus':
          {
            outlineColor: dakotaTheme.colors.blue.base,
            outlineWidth: 2,
          },
        color: dakotaTheme.colors.gray[700],
      }}
    />
  );
};
