import { Inspection, InspectionStatus, Summary } from '@dakota/platform-client';
import { AssigneeAvatar } from 'components/Avatar';
import { PriorityIcon } from 'components/PriorityIcon';
import { useDateFormat } from 'hooks/useDateFormat';
import { FC } from 'react';

type InspectionDetailsButtonProps = {
  disabled?: boolean;
  inspection: Inspection;
  onClick: () => void;
};

export const InspectionDetailsButton: FC<InspectionDetailsButtonProps> = ({
  disabled,
  inspection,
  onClick,
}) => {
  const { formatBackendDate } = useDateFormat();

  const userSummary = Summary.fromJS({
    id: inspection.userId,
    name: inspection.userName,
  });

  return (
    <button
      className='flex-1 flex sm:items-center gap-4'
      disabled={disabled}
      onClick={onClick}
    >
      <AssigneeAvatar user={userSummary} />
      <div>
        {inspection.status === InspectionStatus.Completed && (
          <>
            Completed Date:{' '}
            <strong>
              {formatBackendDate(inspection.timeline.endDate as string)}
            </strong>
          </>
        )}
        {inspection.status === InspectionStatus.Canceled && (
          <>
            Canceled Date:{' '}
            <strong>
              {formatBackendDate(inspection.timeline.endDate as string)}
            </strong>
          </>
        )}
        {[
          InspectionStatus.InProgress,
          InspectionStatus.Overdue,
          InspectionStatus.Scheduled,
        ].includes(inspection.status) && (
          <>
            Due:{' '}
            <strong>
              {formatBackendDate(inspection.timeline.scheduledDate)}
            </strong>
          </>
        )}
      </div>
      <div className='flex items-center gap-1'>
        Priority: <PriorityIcon priority={inspection.priority} />
      </div>
      <div>
        Facility: <strong>{inspection.facility.name}</strong>
      </div>
    </button>
  );
};
