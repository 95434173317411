import { InspectionStatus } from '@dakota/platform-client';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@mui/material';
import { clsx } from 'clsx';
import { configSlice } from 'features/config/configSlice';
import { getInspectionDetails } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/store';

import { ConductInspection } from './ConductInspection';
import { StartOptions } from './StartOptions';

type InspectionLoaderProps = {
  /**
   * The source where the user is redirected after closing the dialog,
   * completing the inspection or canceling the inspection.
   *
   * If the value is `'email'` or `null`, the user is redirected to the
   * home page, which displays the dashboard.
   *
   * @default `null`
   */
  source?: null | string;
};

export const InspectionLoader: FC<InspectionLoaderProps> = ({
  source = null,
}) => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const inspectionDetails = useSelector(
    inspectionSlice.selectors.inspectionDetails,
  );

  const navigateAway = useCallback(() => {
    navigate(source === null || source === 'email' ? '/' : source);
  }, [navigate, source]);

  useEffect(() => {
    void dispatch(getInspectionDetails({ baseUrl, id, token }));
  }, [baseUrl, dispatch, id, token]);

  const loadingAnimation = (text: string) => (
    <div
      className={clsx(
        'w-full h-full flex flex-col gap-2 justify-center items-center',
        'text-lg text-gray-500 animate-pulse',
      )}
    >
      <ClipboardDocumentIcon className='w-24 h-24' />
      {text}
    </div>
  );

  if (!inspectionDetails) {
    return loadingAnimation('Loading inspection...');
  }

  // at this point, we know we have an inspection object

  if (
    inspectionDetails.status === InspectionStatus.Overdue ||
    inspectionDetails.status === InspectionStatus.Scheduled
  ) {
    return (
      <Dialog fullScreen open>
        <StartOptions
          inspectionDetails={inspectionDetails}
          navigateAway={navigateAway}
        />
      </Dialog>
    );
  }

  return (
    <div className='h-full bg-white'>
      <ConductInspection
        inspectionDetails={inspectionDetails}
        onClose={navigateAway}
      />
    </div>
  );
};
