import resolveConfig from 'tailwindcss/resolveConfig';
import { useMediaQuery } from 'usehooks-ts';

import tailwindConfig from '../../tailwind.config';

const { theme } = resolveConfig(tailwindConfig);

export const useBreakpoints = () => {
  const isMobile = useMediaQuery(`(max-width: ${theme.screens.sm})`);

  return { isDesktop: !isMobile, isMobile };
};
