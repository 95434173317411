import { Question } from '@dakota/platform-client';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Menu } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import PermissionGuard from 'auth/PermissionGuard';
import { clsx } from 'clsx';
import Button from 'components/Button';
import DakotaLibraryLogo from 'components/DakotaLibraryLogo';
import { PageHeader } from 'components/PageHeader';
import { Table } from 'components/Table';
import { questionsSlice } from 'features/questions/questionsSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import useQuestionFilters from 'hooks/useQuestionFilters';
import FormBuilderStepperDialog from 'Pages/FormBuilder/stepper/stepperDialog';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

import AddEditQuestions from './AddEditQuestions';
import { AddMultipleQuestions } from './AddMultipleQuestions';
import QuestionsMenu from './QuestionsMenu';

const InspectionsQuestions = () => {
  const { t } = useInternationalization();
  const dispatch = useAppDispatch();

  const { stopTracking } = usePageLoadTracking();

  /**
   * When multiple questions were added, they will be in this array.
   */
  const addedQuestions = useSelector(questionsSlice.selectors.addedQuestions);

  const isLoadingAllQuestions = useSelector(
    questionsSlice.selectors.isLoadingQuestions,
  );

  const {
    clearAllButton,
    filteredQuestions,
    highlight,
    hookDataLoaded,
    itemGroupChips,
    itemGroupFilter,
    itemTypeChips,
    itemTypeFilter,
    searchInput,
    statusFilter,
  } = useQuestionFilters({
    itemGroupClasses: 'w-48',
    itemTypeClasses: 'w-56',
    statusClasses: 'w-28',
    useStatusFilter: true,
  });

  // We need to stop tracking when both the hook and page data have loaded
  useEffect(() => {
    if (hookDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, stopTracking]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isQuestionsOptionsOpen =
    Boolean(anchorEl) && addedQuestions.length === 0;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [isAddSingleModalOpen, setIsAddSingleModalOpen] = useState(false);
  const [isAddMultipleModalOpen, setIsAddMultipleModalOpen] = useState(false);

  const textGray400 = 'text-gray-400';
  const getInactiveClassName = (inactive: boolean) =>
    `flex flex-col space-y-1 ${inactive ? textGray400 : ''}`;

  const questionColumns: GridColDef<Question>[] = [
    {
      display: 'flex',
      field: 'itemType',
      headerName: 'Item Type',
      renderCell: (params) => {
        const question = params.row;
        return (
          <div className='flex-col'>
            <div
              className={clsx(
                'text-pretty',
                question.inactive ? 'text-gray-400' : 'text-gray-700',
              )}
            >
              {highlight(question.itemType.name)}
            </div>
            <div className='text-pretty text-xs font-normal text-gray-400'>
              {highlight(question.itemType.group)}
            </div>
          </div>
        );
      },
      width: 250,
    },
    {
      display: 'flex',
      field: 'text',
      flex: 1,
      headerName: 'Question',
      minWidth: 400,
      // TODO: add `order: 1` here when adding mobile view to the new table
      renderCell: (params) => {
        const question = params.row;
        return (
          <div
            className={clsx('text-balance whitespace-pre-line', {
              'text-gray-400': question.inactive,
            })}
            data-testid={`question-${question.id}`}
          >
            {highlight(question.text)}
            {question.isPlatformDefined && <DakotaLibraryLogo />}
          </div>
        );
      },
    },
    {
      display: 'flex',
      field: 'citation',
      headerName: 'Citation',
      minWidth: 150,
      renderCell: (params) => {
        const question = params.row;
        return (
          <div className={getInactiveClassName(question.inactive)}>
            {highlight(question.citation)}
          </div>
        );
      },
    },
    {
      display: 'flex',
      field: 'answerType',
      headerName: 'Response type',
      minWidth: 200,
      renderCell: (params) => {
        const question = params.row;
        return (
          <div className={question.inactive ? textGray400 : ''}>
            {question.answerType.label}
          </div>
        );
      },
    },
    {
      display: 'flex',
      field: 'menu',
      headerName: '',
      renderCell: (params) =>
        !params.row.isPlatformDefined && (
          <QuestionsMenu question={params.row} />
        ),
      width: 50,
    },
  ];

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader
        scaffold={['Inspection Questions', 'Manage Content']}
        title={t('questions.title')}
      >
        <PermissionGuard permissions={Permission.CreateQuestion}>
          <Button
            icon={<ChevronDownIcon />}
            iconPosition='right'
            id='add-question-button'
            onClick={handleClick}
          >
            Add Question
          </Button>
        </PermissionGuard>
      </PageHeader>
      <div className='filters-container'>
        {searchInput}
        {itemGroupFilter}
        {itemTypeFilter}
        <PermissionGuard permissions={Permission.Admin}>
          {statusFilter}
        </PermissionGuard>
        {clearAllButton}
      </div>
      {itemGroupChips}
      {itemTypeChips}
      <Table
        columns={questionColumns}
        data-testid='questions-table'
        density='comfortable'
        getEstimatedRowHeight={() => 100}
        getRowHeight={() => 'auto'}
        loading={isLoadingAllQuestions}
        rows={filteredQuestions}
        sx={{
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
      />
      {isQuestionsOptionsOpen && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          aria-labelledby='user-menu-button'
          id='user-menu'
          onClose={() => setAnchorEl(null)}
          open
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <div
            className={clsx(
              'min-w-32 py-2 px-4 flex flex-col items-start gap-2',
              '*:gap-2 *:w-full *:flex *:justify-between *:items-center',
            )}
          >
            <button
              className='group'
              id='add-single-question-button'
              onClick={() => setIsAddSingleModalOpen(true)}
            >
              Single question
              <CheckIcon className='h-5 opacity-0 group-hover:opacity-100 transition-opacity text-green-base' />
            </button>
            <button
              className='group'
              id='add-multiple-questions-button'
              onClick={() => setIsAddMultipleModalOpen(true)}
            >
              Multiple questions
              <CheckIcon className='h-5 opacity-0 group-hover:opacity-100 transition-opacity text-green-base' />
            </button>
          </div>
        </Menu>
      )}
      {isAddSingleModalOpen && (
        <AddEditQuestions
          isOpen={isAddSingleModalOpen}
          setIsOpen={setIsAddSingleModalOpen}
        />
      )}
      {isAddMultipleModalOpen && (
        <AddMultipleQuestions
          onClose={() => setIsAddMultipleModalOpen(false)}
        />
      )}
      {addedQuestions.length > 0 && (
        <FormBuilderStepperDialog
          onClose={() => dispatch(questionsSlice.actions.clearAddedQuestions())}
          questions={addedQuestions}
        />
      )}
    </div>
  );
};

export default InspectionsQuestions;
