/**
 * Map of human-readable permissions to the actual permission keys.
 */
export const Permission = {
  Admin: 'admin',
  CancelInspection: 'cancel:inspections',
  CommentInspection: 'comment:inspections',
  CommentQuestion: 'comment:questions',
  CreateFacility: 'create:facilities',
  CreateFacilityForm: 'create:facility-forms',
  CreateGlobalForm: 'create:global-forms',
  CreateInspection: 'create:inspections',
  CreateItem: 'create:items',
  CreateItemMetadata: 'create:item-metadata',
  CreateQuestion: 'create:questions',
  CreateRoleMembers: 'create:role-members',
  CreateRoles: 'create:roles',
  CreateTask: 'create:tasks',
  CreateUser: 'create:users',
  CreateUserAccess: 'create:user-access',
  DeactivateFacility: 'delete:facilities',
  DeactivateFacilityForm: 'delete:facility-forms',
  DeactivateGlobalForm: 'delete:global-forms',
  DeactivateItemMetadata: 'delete:item-metadata',
  DeactivateQuestion: 'delete:questions',
  DeactivateUser: 'delete:users',
  DeleteFacility: 'delete:facilities',
  DeleteFacilityForm: 'delete:facility-forms',
  DeleteGlobalForm: 'delete:global-forms',
  DeleteItem: 'delete:items',
  DeleteItemMetadata: 'delete:item-metadata',
  DeleteQuestion: 'delete:questions',
  DeleteRoleMembers: 'delete:role-members',
  DeleteRoles: 'delete:roles',
  DeleteUserAccess: 'delete:user-access',
  ManageInspection: 'manage:inspections',
  ManageUsers: 'manage:users',
  PerformInspection: 'perform:inspections',
  ReadAnswer: 'read:answers',
  ReadFacility: 'read:facilities',
  ReadForm: 'read:forms',
  ReadInspection: 'read:inspections',
  ReadItem: 'read:items',
  ReadItemMetadata: 'read:item-metadata',
  ReadQuestion: 'read:questions',
  ReadRoleMembers: 'read:role-members',
  ReadRoles: 'read:roles',
  ReadTasks: 'read:tasks',
  ReadUser: 'read:users',
  ReadUserAccess: 'read:user-access',
  UpdateFacility: 'update:facilities',
  UpdateFacilityForm: 'update:facility-forms',
  UpdateGlobalForm: 'update:global-forms',
  UpdateInspection: 'update:inspections',
  UpdateItem: 'update:items',
  UpdateItemMetadata: 'update:item-metadata',
  UpdateQuestion: 'update:questions',
  UpdateRoles: 'update:roles',
  UpdateTask: 'update:tasks',
  UpdateUser: 'update:users',
} as const;
