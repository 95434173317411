import { LocalDate } from '@js-joda/core';
import Autocomplete from 'components/Autocomplete';
import isEqual from 'lodash/isEqual';
import { FC, useEffect, useState } from 'react';
import { Frequency, RRule } from 'rrule';

import { CustomRecurrence } from './CustomRecurrence';
import { recurrenceOptionToString } from './print';
import {
  getDefaultEndCondition,
  getDefaultOption,
  getDefaultRepetition,
  toRRuleWeekday,
} from './rrule';
import {
  RecurrenceEndCondition,
  RecurrenceOption,
  RecurrenceRepetition,
} from './types';

type RecurrenceProps = {
  /**
   * The initial date of the recurrence.
   */
  initialDate: LocalDate;
  /**
   * The initial recurrence rule.
   * If not present, it will default to "does not repeat"
   * (frequency daily, interval 1, count 1).
   */
  initialRRule?: string;
  /**
   * Report the recurrence rule as it changes.
   */
  onChange: (rrule: string) => void;
};

export const Recurrence: FC<RecurrenceProps> = ({
  initialDate,
  initialRRule,
  onChange,
}) => {
  const [option, setOption] = useState(
    getDefaultOption(initialDate, initialRRule),
  );
  const [repetition, setRepetition] = useState<RecurrenceRepetition>(
    getDefaultRepetition(initialRRule),
  );
  const [end, setEnd] = useState<RecurrenceEndCondition>(
    getDefaultEndCondition(initialRRule),
  );
  const [lastReported, setLastReported] = useState('');

  useEffect(() => {
    setOption(getDefaultOption(initialDate, initialRRule));
    // Don't update repetition if values are the same
    setRepetition((prev) => {
      const candidate = getDefaultRepetition(initialRRule);
      return isEqual(prev, candidate) ? prev : candidate;
    });
    // Don't update end if values are the same
    setEnd((prev) => {
      const candidate = getDefaultEndCondition(initialRRule);
      return isEqual(prev, candidate) ? prev : candidate;
    });
  }, [initialDate, initialRRule]);

  useEffect(() => {
    // Remove the `RRULE:` prefix so that we only report the actual rule
    const rule = new RRule({ ...repetition, ...end })
      .toString()
      .replace('RRULE:', '');

    // Report the rule only if it has changed
    if (rule !== lastReported) {
      onChange(rule);
      setLastReported(rule);
    }
  }, [end, lastReported, onChange, repetition]);

  /**
   * This function takes care of the non-custom options in the main dropdown.
   */
  const onChangeOption = (newOption: RecurrenceOption) => {
    setOption(newOption);
    if (newOption === RecurrenceOption.NOT_REPEATING) {
      setRepetition({ freq: Frequency.DAILY, interval: 1 });
      setEnd({ count: 1, until: null });
    } else if (newOption === RecurrenceOption.DAILY) {
      setRepetition({ freq: Frequency.DAILY, interval: 1 });
      setEnd({ count: null, until: null });
    } else if (
      newOption === RecurrenceOption.WEEKLY_ON_WEEKDAY ||
      // Custom recurrence, default to weekly if the previous
      // frequency type was 'does not repeat'
      (newOption === RecurrenceOption.CUSTOM &&
        option === RecurrenceOption.NOT_REPEATING)
    ) {
      setRepetition({
        byweekday: [toRRuleWeekday(initialDate)],
        freq: Frequency.WEEKLY,
        interval: 1,
      });
      setEnd({ count: null, until: null });
    } else if (newOption === RecurrenceOption.MONTHLY_ON_DAY) {
      setRepetition({
        bymonthday: initialDate.dayOfMonth(),
        freq: Frequency.MONTHLY,
        interval: 1,
      });
      setEnd({ count: null, until: null });
    } else if (newOption === RecurrenceOption.YEARLY_ON_DAY_MONTH) {
      setRepetition({
        bymonth: initialDate.monthValue(),
        bymonthday: initialDate.dayOfMonth(),
        freq: Frequency.YEARLY,
        interval: 1,
      });
      setEnd({ count: null, until: null });
    }
  };

  const onChangeInterval = (interval: number) =>
    setRepetition((prev) => ({ ...prev, interval }));

  const options = [
    RecurrenceOption.NOT_REPEATING,
    RecurrenceOption.DAILY,
    RecurrenceOption.WEEKLY_ON_WEEKDAY,
    RecurrenceOption.MONTHLY_ON_DAY,
    RecurrenceOption.YEARLY_ON_DAY_MONTH,
    RecurrenceOption.CUSTOM,
  ];

  return (
    <div className='w-[300px] flex flex-col gap-4'>
      <Autocomplete
        getOptionLabel={(opt) => recurrenceOptionToString(opt, initialDate)}
        id='recurrence-option-selector'
        onChange={onChangeOption}
        options={options}
        value={option}
      />
      {option === RecurrenceOption.CUSTOM && (
        <CustomRecurrence
          initialDate={initialDate}
          initialEndCondition={end}
          initialRepetition={repetition}
          onChangeEndCondition={setEnd}
          onChangeInterval={onChangeInterval}
          onChangeRepetition={setRepetition}
        />
      )}
    </div>
  );
};
