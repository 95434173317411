import { Dialog, DialogContent } from '@mui/material';
import Button from 'components/Button';
import { FC, useState } from 'react';

import { EditSeriesChoice } from '../types';

type ChoiceDialogProps = {
  /**
   * Callback to close the dialog.
   */
  onClose: () => void;
  /**
   * Callback to continue with the selected option.
   */
  onContinue: (option: EditSeriesChoice) => void;
  /**
   * Whether the dialog is open.
   */
  open: boolean;
};

export const ChoiceDialog: FC<ChoiceDialogProps> = ({
  onClose,
  onContinue,
  open,
}) => {
  const [selectedOption, setSelectedOption] = useState<EditSeriesChoice>(
    EditSeriesChoice.CurrentAndFollowing,
  );

  return (
    <Dialog onClose={onClose} open={open}>
      <div className='px-4 pt-4 text-base font-semibold'>
        Edit Recurring Task
      </div>
      <DialogContent className='px-4 py-2'>
        <div className='flex flex-col gap-3'>
          <label className='flex items-center gap-2'>
            <input
              checked={selectedOption === EditSeriesChoice.CurrentAndFollowing}
              name='edit-option'
              onChange={() =>
                setSelectedOption(EditSeriesChoice.CurrentAndFollowing)
              }
              type='radio'
              value='currentAndFollowing'
            />
            <span className='text-sm font-normal'>
              This and following events
            </span>
          </label>
          <label className='flex items-center gap-2'>
            <input
              checked={selectedOption === EditSeriesChoice.All}
              name='edit-option'
              onChange={() => setSelectedOption(EditSeriesChoice.All)}
              type='radio'
              value='all'
            />
            <span className='text-sm font-normal'>All events</span>
          </label>
        </div>
      </DialogContent>
      <div className='flex gap-2 px-6 py-3 justify-start border-t border-gray-200'>
        <Button onClick={() => onContinue(selectedOption)}>Continue</Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};
