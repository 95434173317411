import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { MultilineInput } from 'components/MultilineInput';
import { Spinner } from 'components/Spinner';
import Tooltip from 'components/Tooltip';
import { useLocalePreference } from 'hooks/useLocalePreference';
import { FC, useState } from 'react';
import ReactTimeAgo from 'react-time-ago';

/**
 * This interface is satisfied by TaskComment and PromptComment, so the
 * component can be used with either and it doesn't have to deal with any
 * internal logic such as backend calling, Redux state, etc.
 */
export interface INote {
  readonly commented: string;
  readonly id: string;
  readonly text: string;
  readonly userId: string;
  readonly userName: string;
}

type Props = {
  /**
   * If present, the note can be deleted and this function will be called
   * when the user clicks the delete button.
   */
  deleteNote?: () => Promise<unknown>;
  /**
   * The maximum length of the text when updating the note.
   * @default 1000
   */
  maxLength?: number;
  /**
   * The note object to display.
   */
  note: INote;
  /**
   * If present, the note can be updated and this function will be called
   * when the user saves a new value.
   */
  updateNote?: (text: string) => Promise<unknown>;
};

export const Note: FC<Props> = ({
  deleteNote,
  maxLength = 1000,
  note,
  updateNote,
}) => {
  const { userLocale } = useLocalePreference();

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editError, setEditError] = useState('');
  const [deleteError, setDeleteError] = useState('');
  const [text, setText] = useState(note.text);

  const saveNewValue = () => {
    setIsSaving(true);
    setEditError('');

    updateNote?.(text)
      .then(() => setIsEditing(false))
      .catch(() => setEditError('Failed to update note'))
      .finally(() => setIsSaving(false));
  };

  const doDeleteNote = () => {
    setIsDeleting(true);

    deleteNote?.()
      .catch(() => setDeleteError('Failed to delete note'))
      .finally(() => setIsDeleting(false));
  };

  const cancelEdit = () => {
    setText(note.text);
    setIsEditing(false);
  };

  return (
    <div
      className={clsx(
        'flex flex-col text-sm gap-1',
        'hover:bg-gray-50 hover:-m-2 hover:p-2 rounded-md',
      )}
      data-testid={`note-${note.id}`}
    >
      <div className='font-semibold'>{note.userName}</div>
      <div className='text-gray-400 text-xs flex items-center justify-between'>
        <ReactTimeAgo date={Date.parse(note.commented)} locale={userLocale} />
        <div>
          {updateNote && (
            <Tooltip arrow title='Edit'>
              <IconButton
                aria-label='Edit note'
                disabled={isEditing || isSaving || isDeleting}
                onClick={() => setIsEditing(true)}
              >
                {isSaving ? <Spinner /> : <PencilIcon className='h-3 w-3' />}
              </IconButton>
            </Tooltip>
          )}
          {deleteNote && (
            <Tooltip arrow title='Delete'>
              <IconButton
                aria-label='Delete note'
                color='error'
                disabled={isDeleting || isSaving}
                onClick={doDeleteNote}
              >
                {isDeleting ? <Spinner /> : <TrashIcon className='h-3 w-3' />}
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {isEditing ? (
        <div className='flex flex-col gap-2'>
          <MultilineInput
            autoFocus
            disabled={isSaving}
            error={editError}
            id={`note-edit-${note.id}`}
            maxLength={maxLength}
            onChange={setText}
            placeholder='Enter note'
            value={text}
          />
          <div className='flex justify-end gap-1 items-center'>
            <Button className='text-xs' onClick={cancelEdit} secondary>
              Cancel
            </Button>
            <Button
              aria-label='Save note'
              className='text-xs'
              disabled={text === note.text}
              loading={isSaving}
              onClick={saveNewValue}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <>
          {/* eslint-disable-next-line sonarjs/mouse-events-a11y */}
          <div
            className={clsx(
              'whitespace-pre-wrap',
              updateNote ? 'cursor-text' : 'cursor-default',
            )}
            onClick={() => {
              if (updateNote && !isDeleting) {
                setIsEditing(true);
              }
            }}
          >
            {note.text}
          </div>
          {deleteError && (
            <div className='text-sm text-red-base'>{deleteError}</div>
          )}
        </>
      )}
    </div>
  );
};
