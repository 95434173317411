import { Facility } from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Dialog } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import PermissionGuard from 'auth/PermissionGuard';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import Chip from 'components/Chip';
import { ClearAllButton } from 'components/ClearAll';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { MuiGridWrapper } from 'components/Table/GridWrapper';
import { MobileTable } from 'components/Table/mobileTable';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import Fuse from 'fuse.js';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useInternationalization } from 'hooks/useInternationalization';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import useToast from 'hooks/useToast';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getHighlighter } from 'utils/highlighter';
import { Permission } from 'utils/permissions';
import { usaStateAbbreviations } from 'utils/state';

import AddEditFacility from './addFacility';
import FacilityMenu from './facilityMenu';
import { ZonesLength } from './zonesLength';

const Facilities: React.FC = () => {
  const { t } = useInternationalization();
  const allFacilities = useSelector(facilitiesSlice.selectors.allFacilities);

  const { isMobile } = useBreakpoints();
  const { stopTracking } = usePageLoadTracking();
  const { setErrorMessage, setSuccessMessage } = useToast();

  const [filteredFacilities, setFilteredFacilities] = useState<Facility[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [isFacilityModalOpen, setIsFacilityModalOpen] = useState(false);
  const [selectedFacilityForZones, setSelectedFacilityForZones] =
    useState<Facility | null>(null);

  type Status = 'Active' | 'All' | 'Inactive';
  const statuses = ['All', 'Active', 'Inactive'] as const;
  const [selectedStatus, setSelectedStatus] = useState<Status>('Active');

  useEffect(() => stopTracking(DataStatus.PreviouslyLoaded), [stopTracking]);

  useEffect(() => {
    let newFacilities = allFacilities;

    if (selectedStatus === 'Active') {
      newFacilities = newFacilities.filter((f) => !f.inactive);
    } else if (selectedStatus === 'Inactive') {
      newFacilities = newFacilities.filter((f) => f.inactive);
    }

    if (selectedStates.length > 0) {
      newFacilities = newFacilities.filter(
        (f) => f.address?.state && selectedStates.includes(f.address.state),
      );
    }

    if (searchQuery) {
      const fuse = new Fuse(newFacilities, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: [
          { name: 'name', weight: 1 },
          { name: 'address.city', weight: 0.3 },
          { name: 'address.state', weight: 0.3 },
          { name: 'contact.name', weight: 0.3 },
        ],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newFacilities = fuse.search(searchQuery).map((r) => r.item);
    }

    setFilteredFacilities(newFacilities);
  }, [allFacilities, searchQuery, selectedStates, selectedStatus]);

  const getClasses = (inactive: boolean) =>
    clsx('flex flex-col space-y-1', { 'text-gray-400': inactive });

  const highlight = getHighlighter(searchQuery);

  const nameColumn: GridColDef<Facility> = {
    field: 'name',
    flex: 1,
    headerName: 'Facility Name',
    minWidth: 250,
    renderCell: (params) => (
      <p className={getClasses(params.row.inactive)}>
        {highlight(params.row.name)}
      </p>
    ),
  };
  const contactColumn: GridColDef<Facility> = {
    field: 'contact',
    headerName: 'Contact',
    minWidth: 200,
    renderCell: (params) => (
      <p className={getClasses(params.row.inactive)}>
        {highlight(params.row.contact?.name)}
      </p>
    ),
  };
  const cityColumn: GridColDef<Facility> = {
    field: 'city',
    headerName: 'City',
    minWidth: 200,
    renderCell: (params) => (
      <p className={getClasses(params.row.inactive)}>
        {highlight(params.row.address?.city)}
      </p>
    ),
  };
  const stateColumn: GridColDef<Facility> = {
    align: 'center',
    field: 'state',
    headerAlign: 'center',
    headerName: 'State',
    renderCell: (params) => (
      <p className={getClasses(params.row.inactive)}>
        {params.row.address?.state}
      </p>
    ),
    width: 80,
  };
  const zonesColumn: GridColDef<Facility> = {
    align: 'center',
    display: 'flex',
    field: 'zones',
    headerAlign: 'center',
    headerName: 'Zones',
    minWidth: 150,
    renderCell: (params) => (
      <ZonesLength
        facility={params.row}
        setSelectedFacilityForZones={setSelectedFacilityForZones}
      />
    ),
  };
  const actionsColumn: GridColDef<Facility> = {
    display: 'flex',
    field: 'menu',
    headerName: '',
    renderCell: (params) => (
      <PermissionGuard
        permissions={[
          Permission.UpdateFacility,
          Permission.DeleteFacility,
          Permission.DeactivateFacility,
        ]}
      >
        <FacilityMenu facility={params.row} />
      </PermissionGuard>
    ),
    resizable: false,
    width: 50,
  };

  const columns = [
    nameColumn,
    contactColumn,
    cityColumn,
    stateColumn,
    zonesColumn,
    actionsColumn,
  ];

  const zonesColumnMobile: GridColDef<Facility> = {
    field: 'zones',
    headerName: 'Zones',
    renderCell: (params) => {
      const zones = params.row.zones;
      if (zones.length === 0) {
        return <p className='text-gray-400 italic'>No zones</p>;
      }
      return (
        <ul className='list-disc list-inside'>
          {zones.map((zone) => (
            <li
              className={clsx({ 'text-gray-400 italic': zone.inactive })}
              key={zone.id}
            >
              {zone.name}
              {zone.inactive && ' (inactive)'}
            </li>
          ))}
        </ul>
      );
    },
  };

  const columnsMobile = [
    nameColumn,
    contactColumn,
    cityColumn,
    stateColumn,
    zonesColumnMobile,
    actionsColumn,
  ];

  const resetFilters = () => {
    setSearchQuery('');
    setSelectedStates([]);
    setSelectedStatus('Active');
  };

  const handleAddFacilityFailure = () => {
    setErrorMessage('Failed to add facility');
  };

  const handleAddFacilitySuccess = () => {
    setIsFacilityModalOpen(false);
    setSuccessMessage('Facility added successfully');
  };

  const removeState = (state: string) => {
    setSelectedStates((prev) => prev.filter((s) => s !== state));
  };

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader
        scaffold={['Facilities', 'My Organization']}
        title={t('facilities.title')}
      >
        <PermissionGuard permissions={Permission.CreateFacility}>
          <Button
            className='bg-green-base px-4 py-2'
            data-testid='add-facility-button'
            icon={<PlusIcon />}
            id='add-facility-button'
            onClick={() => setIsFacilityModalOpen(true)}
          >
            Add Facility
          </Button>
        </PermissionGuard>
      </PageHeader>
      <div className='filters-container'>
        <SearchInput
          data-testid='facilities-search-input'
          id='facilities-search-input'
          onSearch={(query: string) => setSearchQuery(query.toLowerCase())}
          value={searchQuery}
        />
        <div className='sm:flex max-sm:grid max-sm:grid-cols-2 gap-3'>
          <Autocomplete
            id='facilities-state-selector'
            label='State'
            multiple
            onChange={setSelectedStates}
            options={usaStateAbbreviations}
            value={selectedStates}
          />
          <PermissionGuard permissions={Permission.DeactivateFacility}>
            <Autocomplete
              className='sm:w-28'
              id='facilities-status-selector'
              label='Status'
              onChange={setSelectedStatus}
              options={statuses}
              value={selectedStatus}
            />
          </PermissionGuard>
        </div>
        {(selectedStates.length > 0 ||
          selectedStatus !== 'Active' ||
          searchQuery.length > 0) && <ClearAllButton onClick={resetFilters} />}
      </div>
      {selectedStates.length > 0 && (
        <div className='flex items-center gap-2 pb-2'>
          <span className='text-sm font-normal pr-1'>States:</span>
          {selectedStates.map((state) => (
            <Chip
              key={state}
              onRemove={() => removeState(state)}
              text={state}
            />
          ))}
        </div>
      )}
      {isMobile ? (
        <MobileTable columns={columnsMobile} rows={filteredFacilities} />
      ) : (
        <MuiGridWrapper columns={columns} rows={filteredFacilities} />
      )}
      {isFacilityModalOpen && (
        <AddEditFacility
          handleFailure={handleAddFacilityFailure}
          handleSuccess={handleAddFacilitySuccess}
          isOpen={isFacilityModalOpen}
          setIsOpen={setIsFacilityModalOpen}
        />
      )}
      {selectedFacilityForZones && (
        <Dialog
          fullWidth
          maxWidth='xs'
          onClose={() => setSelectedFacilityForZones(null)}
          open
        >
          <div className='flex flex-col gap-3 p-6 text-gray-500'>
            <div className='text-xl font-semibold text-gray-900'>
              Zones for {selectedFacilityForZones.name}
            </div>
            <ol className='list-decimal list-inside' data-testid='zone-list'>
              {selectedFacilityForZones.zones.map((zone) => (
                <li
                  className={`${zone.inactive && 'text-gray-400'}`}
                  data-testid='zone-list-item'
                  key={zone.id}
                >
                  {zone.name} {zone.inactive && <span>(inactive)</span>}
                </li>
              ))}
            </ol>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default Facilities;
