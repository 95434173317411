import { SecuredFileDetails } from '@dakota/platform-client';
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { getFile } from './filesActions';

type FileWithId = { id: string } & SecuredFileDetails;

const filesAdapter = createEntityAdapter<FileWithId>();

type FilesState = ReturnType<typeof filesAdapter.getInitialState>;

const initialState: FilesState = filesAdapter.getInitialState();

export const filesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getFile.fulfilled, (state, action) => {
      const { attachmentId: id } = action.meta.arg;
      filesAdapter.upsertOne(state, { ...action.payload, id } as FileWithId);
    });
  },
  initialState,
  name: 'files',
  reducers: {},
  selectors: {
    /**
     * Function that returns a file by its ID, or `undefined` if the
     * ID doesn't exist in the current collection.
     */
    getFileById: createSelector(
      (state: FilesState) => state.entities,
      (entities) => (id: string) =>
        Object.hasOwn(entities, id) ? entities[id] : undefined,
    ),
  },
});
