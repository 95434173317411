import { InspectionDetails, PromptComment } from '@dakota/platform-client';
import { Note } from 'components/Note';
import { configSlice } from 'features/config/configSlice';
import { deleteNote, updateNote } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import useToast from 'hooks/useToast';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

type InspectionNoteProps = {
  note: PromptComment;
  noteIndex: number;
  promptIndex: number;
  sectionIndex: number;
};

export const InspectionNote: FC<InspectionNoteProps> = ({
  note,
  noteIndex,
  promptIndex,
  sectionIndex,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const canUpdateInspection = useCheckPermission(Permission.UpdateInspection);
  const isAdmin = useCheckPermission(Permission.Admin);
  /** People Managers can edit comments and they have this permission. */
  const isPeopleManager = useCheckPermission(Permission.ManageUsers);
  const canEdit = isAdmin || isPeopleManager || canUpdateInspection;

  const inspection = useSelector(
    inspectionSlice.selectors.inspectionDetails,
  ) as InspectionDetails;

  const { setErrorMessage, setSuccessMessage } = useToast();

  const updateText = (text: string) =>
    dispatch(
      updateNote({
        baseUrl,
        commentId: note.id,
        inspectionId: inspection.id,
        noteIndex,
        promptId: note.promptId,
        promptIndex,
        sectionIndex,
        text,
        token,
      }),
    )
      .unwrap()
      .then((response) => response)
      .catch(() => setErrorMessage('Failed to update note'));

  const removeNote = () =>
    dispatch(
      deleteNote({
        baseUrl,
        commentId: note.id,
        inspectionId: inspection.id,
        noteIndex,
        promptId: note.promptId,
        promptIndex,
        sectionIndex,
        token,
      }),
    )
      .unwrap()
      .then((response) => {
        setSuccessMessage('Note removed');
        return response;
      })
      .catch(() => setErrorMessage('Failed to delete note'));

  return (
    <Note
      deleteNote={
        canEdit || currentUser.id === inspection.userId ? removeNote : undefined
      }
      note={note}
      updateNote={
        canEdit || currentUser.id === inspection.userId ? updateText : undefined
      }
    />
  );
};
