import { MultilineInput } from 'components/MultilineInput';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useAppConfiguration } from 'hooks/useAppConfiguration';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Description: FC = () => {
  const dispatch = useAppDispatch();

  const { getConfig } = useAppConfiguration();

  const description = useSelector(taskEditSlice.selectors.description);

  const hasErrorFiles = useSelector(taskEditSlice.selectors.hasErrorFiles);

  return (
    <>
      <label htmlFor='task-details-input'>Description</label>
      <MultilineInput
        aria-label='Description'
        disabled={hasErrorFiles}
        id='task-details-input'
        maxLength={getConfig('TaskDescriptionMaxLength')}
        onChange={(value) =>
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'description',
              value,
            }),
          )
        }
        placeholder='Enter description...'
        value={description}
      />
    </>
  );
};
