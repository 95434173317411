import { AddAttachments } from 'Pages/Tasks/AddAttachments';
import { FC } from 'react';

export const Attachments: FC = () => {
  return (
    <>
      <div className='font-bold'>Attachments</div>
      <AddAttachments />
    </>
  );
};
