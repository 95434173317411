import { Priority, SearchStatus, TaskState } from '@dakota/platform-client';

export type ExtendedTaskState = 'overdue' | TaskState;

export const getStatusLabel = (status: ExtendedTaskState) => {
  switch (status) {
    case 'overdue':
      return 'Overdue';
    case TaskState.Canceled:
      return 'Canceled';
    case TaskState.Completed:
      return 'Completed';
    case TaskState.InProgress:
      return 'In Progress';
    case TaskState.Scheduled:
      return 'Scheduled';
  }
};
export const getSearchStatusLabel = (status: SearchStatus) => {
  switch (status) {
    case SearchStatus.Canceled:
      return 'Canceled';
    case SearchStatus.Completed:
      return 'Completed';
    case SearchStatus.InProgress:
      return 'In Progress';
    case SearchStatus.Overdue:
      return 'Overdue';
    case SearchStatus.Scheduled:
      return 'Scheduled';
  }
};

export const toSearchStatus = (status: TaskState): SearchStatus => {
  switch (status) {
    case TaskState.Canceled:
      return SearchStatus.Canceled;
    case TaskState.Completed:
      return SearchStatus.Completed;
    case TaskState.InProgress:
      return SearchStatus.InProgress;
    case TaskState.Scheduled:
      return SearchStatus.Scheduled;
  }
};

export const getPriorityLabel = (priority: Priority) => {
  switch (priority) {
    case Priority.High:
      return 'High';
    case Priority.Low:
      return 'Low';
    case Priority.Medium:
      return 'Medium';
  }
};

export enum EditSeriesChoice {
  All = 'all',
  CurrentAndFollowing = 'currentAndFollowing',
}
