import { PromptMedia, TaskMedia } from '@dakota/platform-client';
import { clsx } from 'clsx';

export const renderImageDetails = (
  media: PromptMedia[] | TaskMedia[],
  shouldRenderThumbnails: boolean,
) =>
  media
    .filter((m) => m.thumbnail)
    .map((m: PromptMedia | TaskMedia) => (
      <div
        className={clsx(
          'flex flex-col justify-between gap-1',
          {
            'border border-gray-400 rounded-md h-64 p-1':
              shouldRenderThumbnails,
          },
          'print:break-inside-avoid-page print:overflow-hidden',
        )}
        key={m.id}
      >
        {shouldRenderThumbnails && (
          <img
            aria-label={`${m.originalName} thumbnail`}
            className='flex-1 object-contain overflow-hidden'
            src={`data:${
              (m.thumbnail as { contentType: string }).contentType
            };base64,${(m.thumbnail as { data: string }).data}`}
          />
        )}
        <div className='flex-none h-12'>
          <p className='text-sm truncate'>{m.originalName}</p>
          <p className='text-xs italic text-gray-500'>
            {m.description ?? 'No description'}
          </p>
        </div>
      </div>
    ));
