import EditableText from 'components/EditableText';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useAppConfiguration } from 'hooks/useAppConfiguration';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Title: FC = () => {
  const dispatch = useAppDispatch();

  const { getConfig } = useAppConfiguration();

  const title = useSelector(taskEditSlice.selectors.title);
  const isSingleInstance = useSelector(
    taskEditSlice.selectors.isSingleInstance,
  );

  return isSingleInstance ? (
    <EditableText
      containerClasses='!block flex-1'
      id='title'
      maxLength={getConfig('TaskTitleMaxLength')}
      onEdit={(value) => {
        dispatch(taskEditSlice.actions.setTaskField({ field: 'title', value }));
      }}
      saveOnEnter
      showIcon={false}
      text={title}
      textClasses='text-xl text-gray-800'
    />
  ) : (
    <div className='text-base font-bold text-gray-800'>{title}</div>
  );
};
