import { Skeleton } from '@mui/material';
import { clsx } from 'clsx';
import { FC, useMemo } from 'react';

type Props = {
  /**
   * Breakpoints for a 3-color semaphore-style background.
   *
   * When the type is `absolute`, numbers between 0 (inclusive)
   * and the first breakpoint (exclusive) will be green, between
   * the first breakpoint (inclusive) and the second breakpoint
   * (exclusive) will be yellow, and greater than or equal to the
   * third breakpoint will be red.
   *
   * When the type is `percent`, the order is reversed, so 100%
   * is green and 0% is red.
   *
   * If not provided, the card will have a light gray background,
   * which conveys a 'neutral' nature for the data it represents.
   *
   * The 3-color system is intentionally ad-hoc for the two charts
   * we use, where the one with absolute values has a positive
   * meaning for lower values, and the one with percentages has a
   * positive meaning for higher values. If we ever need to support
   * more use cases, we can add a prop to indicate `ascending` or
   * `descending` to indicate the order of the breakpoints.
   *
   * @default `undefined`
   */
  bgBreakpoints?: number[];
  /**
   * When `true`, force a loading animation instead of showing the
   * numberic value provided.
   * @default `false`
   */
  loading?: boolean;
  title: string;
  /**
   * Indicates how the value should be displayed. Percentages will
   * be preceded by a `%` sign.
   * @default 'absolute'
   */
  type?: 'absolute' | 'percent';
  /**
   * @default `undefined`
   */
  value?: number;
};

const SummaryCard: FC<Props> = ({
  bgBreakpoints = undefined,
  loading = false,
  title,
  type = 'absolute',
  value = undefined,
}) => {
  const bgClass = useMemo(() => {
    // Default to 'neutral' light-gray background
    let bg = 'bg-gray-50 hover:bg-gray-200';
    if (bgBreakpoints && value !== undefined) {
      if (
        (type === 'absolute' && value >= bgBreakpoints[1]) ||
        (type === 'percent' && value <= bgBreakpoints[0])
      ) {
        bg = 'bg-semaphore-redLight hover:bg-semaphore-redMedium';
      } else if (
        (type === 'absolute' && value >= bgBreakpoints[0]) ||
        (type === 'percent' && value <= bgBreakpoints[1])
      ) {
        bg = 'bg-semaphore-yellowLight hover:bg-semaphore-yellowMedium';
      } else {
        bg = 'bg-semaphore-greenLight hover:bg-semaphore-greenMedium';
      }
    }
    return bg;
  }, [bgBreakpoints, type, value]);

  // We're not loading but the value is `undefined`, and we display 'N/A'
  const isUnknown = !loading && value === undefined;
  // We have an actual numeric value to display (potentially zero)
  const hasValue = !loading && value !== undefined;

  return (
    <div
      className={clsx(
        'group h-28 flex flex-col justify-center items-center',
        'transition-all *:transition-all p-6',
        'rounded-lg border border-gray-300 text-center',
        bgClass,
      )}
      data-testid='summary-card'
    >
      <div
        className={clsx(
          'text-gray-500 text-sm font-normal',
          'group-hover:text-gray-700',
        )}
      >
        {title}
      </div>
      <div
        className={clsx(
          'text-gray-900 text-3xl font-semibold',
          'group-hover:max-sm:scale-125 group-hover:sm:scale-150',
          'group-hover:text-black',
        )}
      >
        {loading && <Skeleton height={40} variant='rounded' width={50} />}
        {isUnknown && 'N/A'}
        {hasValue && `${value}${type === 'percent' ? '%' : ''}`}
      </div>
    </div>
  );
};

export default SummaryCard;
