export const useAppConfiguration = () => {
  const appConfiguration = {
    AttachmentDescriptionMaxLength: 1000,
    NoteMaxLength: 1000,
    TaskDescriptionMaxLength: 6000,
    TaskTitleMaxLength: 50,
    TemplateDescriptionMaxLength: 500,
    TemplateTitleMaxLength: 50,
  };

  const getConfig = (key: keyof typeof appConfiguration) => {
    return appConfiguration[key];
  };

  return { getConfig };
};
