import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';

type Props = {
  /**
   * Callback to close the dialog.
   */
  onClose: () => void;
};

export const Header: FC<Props> = ({ onClose }) => (
  <div className='flex items-center p-4 bg-green-base text-white h-16'>
    <div
      aria-label='Task header'
      className='flex-1 truncate text-xl font-semibold'
      id='task-header'
    >
      Task Details
    </div>
    <div className='flex-none flex justify-end'>
      <button
        aria-label='Close'
        className='flex items-center justify-center h-full'
        id='close-create-task'
        onClick={onClose}
      >
        <XMarkIcon className='h-6 w-6' />
      </button>
    </div>
  </div>
);
