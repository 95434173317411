import {
  isApiException,
  isValidationProblemDetails,
} from '@dakota/client-common';
import {
  AddMediaParams,
  CreateTaskRequest,
  ListTasksParams,
  SplitTaskSeriesRequest,
  TaskComment,
  TaskMedia,
  TasksClient,
  TaskState,
  UpdateNonRecurringTaskInstanceRequest,
  UpdateTaskInstanceRequest,
  UpdateTaskSeriesRequest,
  UpdateTaskStatusRequest,
} from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const listTasks = createAsyncThunk(
  'tasks/listTasks',
  async (params: ClientData & ListTasksParams) => {
    const client = getClient(TasksClient, params);
    const response = await client.listTasks(params);
    return response.result;
  },
);

export const getTaskDetails = createAsyncThunk(
  'tasks/getTaskDetails',
  async (params: { date: string; seriesId: string } & ClientData) => {
    const client = getClient(TasksClient, params);
    const response = await client.getTaskInstance(params.seriesId, params.date);
    return response.result;
  },
);

export const createTask = createAsyncThunk(
  'tasks/createTask',
  async (params: { task: CreateTaskRequest } & ClientData) => {
    const client = getClient(TasksClient, params);

    const response = await client.createTaskSeries(params.task);
    return response.result;
  },
);

/**
 * Add an attachment to a task instance, identified by its parent series ID and
 * its scheduled date.
 */
export const addAttachmentToInstance = createAsyncThunk(
  'tasks/addAttachmentToInstance',
  async (
    params: {
      dueDate: string;
      options: AddMediaParams;
      seriesId: string;
    } & ClientData,
    { rejectWithValue },
  ) => {
    const client = getClient(TasksClient, params);
    try {
      const response = await client.addMediaToTask(
        params.seriesId,
        params.dueDate,
        params.options,
      );
      return response.result;
    } catch (e: unknown) {
      if (isApiException(e) && isValidationProblemDetails(e.result)) {
        return rejectWithValue(e.result.errors.mediaFile[0]);
      }
    }
  },
);

export const updateTaskInstance = createAsyncThunk(
  'tasks/updateTaskInstance',
  async (
    params: {
      body: UpdateTaskInstanceRequest;
      dueDate: string;
      seriesId: string;
    } & ClientData,
  ) => {
    const client = getClient(TasksClient, params);

    const response = await client.updateTaskInstance(
      params.seriesId,
      params.dueDate,
      params.body,
    );
    return response.result;
  },
);

export const updateTaskStatus = createAsyncThunk(
  'tasks/updateTaskStatus',
  async (
    params: {
      body: { status: TaskState };
      dueDate: string;
      seriesId: string;
    } & ClientData,
  ) => {
    const client = getClient(TasksClient, params);

    const response = await client.updateTaskStatus(
      params.seriesId,
      params.dueDate,
      params.body as UpdateTaskStatusRequest,
    );

    return response.result;
  },
);

export const updateAttachment = createAsyncThunk(
  'tasks/updateAttachment',
  async (
    params: {
      attachmentId: string;
      description: string;
      dueDate: string;
      seriesId: string;
      taskId: string;
    } & ClientData,
  ) => {
    const client = getClient(TasksClient, params);
    const body = {
      description: params.description || undefined,
    } as TaskMedia;
    const response = await client.updateMediaForTask(
      params.seriesId,
      params.dueDate,
      params.attachmentId,
      body,
    );
    return response.result;
  },
);

export const deleteAttachment = createAsyncThunk(
  'tasks/deleteAttachment',
  async (
    params: {
      attachmentId: string;
      dueDate: string;
      seriesId: string;
      taskId: string;
    } & ClientData,
  ) => {
    const client = getClient(TasksClient, params);
    await client.deleteMediaForTask(
      params.seriesId,
      params.dueDate,
      params.attachmentId,
    );
  },
);

export const getTaskSeries = createAsyncThunk(
  'tasks/getTaskSeries',
  async (params: { id: string } & ClientData) => {
    const client = getClient(TasksClient, params);
    const response = await client.getTaskSeries(params.id);
    return response.result;
  },
);

export const updateTaskNote = createAsyncThunk(
  'tasks/updateTaskNote',
  async (
    params: {
      dueDate: string;
      id: string;
      note: string;
      seriesId: string;
      taskId: string;
    } & ClientData,
  ) => {
    const client = getClient(TasksClient, params);
    const body = {
      text: params.note,
    } as TaskComment;
    const response = await client.updateCommentForTask(
      params.seriesId,
      params.dueDate,
      params.id,
      body,
    );
    return response.result;
  },
);

export const deleteTaskNote = createAsyncThunk(
  'tasks/deleteTaskNote',
  async (
    params: {
      dueDate: string;
      id: string;
      seriesId: string;
      taskId: string;
    } & ClientData,
  ) => {
    const client = getClient(TasksClient, params);
    await client.deleteCommentForTask(
      params.seriesId,
      params.dueDate,
      params.id,
    );
  },
);

export const addTaskNote = createAsyncThunk(
  'tasks/addTaskNote',
  async (
    params: {
      dueDate: string;
      note: string;
      seriesId: string;
      taskId: string;
    } & ClientData,
  ) => {
    const client = getClient(TasksClient, params);
    const body = { text: params.note } as TaskComment;
    const response = await client.addCommentToTask(
      params.seriesId,
      params.dueDate,
      body,
    );
    return response.result;
  },
);

export const updateTaskSeries = createAsyncThunk(
  'tasks/updateTaskSeries',
  async (
    params: {
      body: UpdateTaskSeriesRequest;
      id: string;
    } & ClientData,
  ) => {
    const client = getClient(TasksClient, params);
    const response = await client.updateTaskSeries(params.id, params.body);
    return response.result;
  },
);

export const splitTaskSeries = createAsyncThunk(
  'tasks/splitTaskSeries',
  async (
    params: {
      body: SplitTaskSeriesRequest;
      id: string;
    } & ClientData,
  ) => {
    const client = getClient(TasksClient, params);
    const response = await client.splitTaskSeries(params.id, params.body);
    return response.result;
  },
);

export const updateNonRecurringTaskInstance = createAsyncThunk(
  'tasks/updateNonRecurringTaskInstance',
  async (
    params: {
      body: UpdateNonRecurringTaskInstanceRequest;
      dueDate: string;
      seriesId: string;
    } & ClientData,
  ) => {
    const client = getClient(TasksClient, params);
    const response = await client.updateNonRecurringTaskInstance(
      params.seriesId,
      params.dueDate,
      params.body,
    );
    return response.result;
  },
);
