import { TaskInstanceDetails } from '@dakota/platform-client';
import { clsx } from 'clsx';
import Button from 'components/Button';
import Confirmation from 'components/SimpleConfirmation';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useEditSeries } from 'hooks/useEditSeries';
import { useTaskEdit } from 'hooks/useTaskEdit';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { Assignee } from './fields/Assignee';
import { Attachments } from './fields/Attachments';
import { Buttons } from './fields/Buttons';
import { Description } from './fields/Description';
import { DueDate } from './fields/DueDate';
import { Facility } from './fields/Facility';
import { Notes } from './fields/Notes';
import { Priority } from './fields/Priority';
import { Recurrence } from './fields/Recurrence';
import { ReportedBy } from './fields/ReportedBy';
import { Status } from './fields/Status';
import { Title } from './fields/Title';
import { Zone } from './fields/Zone';
import { Header } from './header';

type EditTaskProps = {
  onClose: () => void;
  task: TaskInstanceDetails;
};

export const EditTask: FC<EditTaskProps> = ({ onClose, task }) => {
  const dispatch = useAppDispatch();

  const hasChanges = useSelector(taskEditSlice.selectors.hasUnsavedChanges);
  const { isSaving, saveTask } = useTaskEdit();

  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const { editSeries, EditSeriesComponent, isLoadingSeries } = useEditSeries({
    isSaving,
    saveTask,
    task,
  });

  const clearAndClose = () => {
    dispatch(taskEditSlice.actions.clear());
    onClose();
  };

  const close = () => {
    if (hasChanges) {
      setShowCloseConfirmation(true);
    } else {
      clearAndClose();
    }
  };

  const confirmClose = () => {
    setShowCloseConfirmation(false);
    clearAndClose();
  };

  useEffect(() => {
    dispatch(taskEditSlice.actions.load(task));
    /**
     * We ONLY load the task into the state when the component mounts.
     * The task object will change every time we add or update a note or
     * attachment, which would reset all the rest of the state, including the
     * partial changes the user might have made, which we DON'T want to lose.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div aria-label='Edit Task Panel' className='flex flex-col h-full'>
      <Header onClose={close} />
      <div className='flex-1 flex flex-col gap-4 overflow-y-auto text-sm p-6 text-gray-700'>
        <div
          className={clsx(
            'flex flex-col gap-4',
            '*:flex *:justify-stretch *:items-center *:min-h-9',
          )}
        >
          <Buttons
            editSeries={editSeries}
            isLoadingSeries={isLoadingSeries}
            task={task}
          />
          <Title />
          <Recurrence />
          <Description />
          <Status />
          <Facility />
          <Zone />
          <Assignee />
          <Priority />
          <DueDate />
        </div>
        <div className='flex flex-col gap-4'>
          <Notes task={task} />
          <Attachments task={task} />
        </div>
        <div
          className={clsx(
            'flex flex-col gap-4',
            '*:flex *:justify-stretch *:items-center *:h-9',
          )}
        >
          <div className='text-base font-bold'>Source</div>
          <ReportedBy />
        </div>
      </div>
      <div className='flex-none border-t p-4 text-sm bg-gray-100 flex gap-2'>
        <Button
          aria-label='Save task'
          disabled={!hasChanges}
          id='save-edit-task'
          loading={isSaving}
          onClick={saveTask}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
        <Button id='cancel-edit-task' onClick={close} secondary>
          Cancel
        </Button>
      </div>
      <EditSeriesComponent />
      {showCloseConfirmation && (
        <Confirmation
          cancelText='Return to form'
          confirmText='Discard'
          onCancel={() => setShowCloseConfirmation(false)}
          onConfirm={confirmClose}
          title='Discard Changes?'
        >
          You have unsaved changes. Are you sure you want to discard them?
        </Confirmation>
      )}
    </div>
  );
};
