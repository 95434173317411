import { Prompt } from '@dakota/platform-client';
import { FC } from 'react';

import { InspectionNote } from './Note';

type NotesProps = {
  prompt: Prompt;
  promptIndex: number;
  sectionIndex: number;
};

export const Notes: FC<NotesProps> = ({
  prompt,
  promptIndex,
  sectionIndex,
}) => {
  if (prompt.comments.length === 0) {
    return null;
  }

  return (
    <div aria-label='Notes Container' className='flex flex-col gap-2 mt-6'>
      <div className='text-base font-bold'>Notes</div>
      <div className='flex flex-col gap-6'>
        {prompt.comments.map((note, noteIndex) => (
          <InspectionNote
            {...{ noteIndex, promptIndex, sectionIndex }}
            key={note.id}
            note={note}
          />
        ))}
      </div>
    </div>
  );
};
