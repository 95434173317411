import { TaskLoader } from 'Pages/Tasks/TaskLoader';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useTaskLoader = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [viewingTask, setViewingTask] = useState<
    { date: string; seriesId: string } | undefined
  >();

  useEffect(() => {
    const date = searchParams.get('date');
    const seriesId = searchParams.get('seriesId');
    if (date && seriesId) {
      setViewingTask({ date, seriesId });
    } else {
      setViewingTask(undefined);
    }
  }, [searchParams]);

  /**
   * When a task is open, this component displays it in the side panel, with a
   * loading indicator while the task object is being fetched. The task is then
   * displayed in editable mode if the user has permissions to edit it, and in
   * read-only mode otherwise.
   */
  const taskViewerComponent = useMemo(
    () =>
      viewingTask ? (
        <TaskLoader
          date={viewingTask.date}
          onClose={() => navigate(location.pathname)}
          seriesId={viewingTask.seriesId}
        />
      ) : null,
    [navigate, viewingTask],
  );

  const openTask = useCallback(
    (date: string, seriesId: string) => setSearchParams({ date, seriesId }),
    [setSearchParams],
  );

  const taskParams = useCallback(
    (date: string, seriesId: string) => `date=${date}&seriesId=${seriesId}`,
    [],
  );

  return { openTask, taskParams, taskViewerComponent };
};
