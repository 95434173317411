import { InspectionDetails } from '@dakota/platform-client';
import { PrinterIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button';
import { answersSlice } from 'features/answers/answersSlice';
import useToast from 'hooks/useToast';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type PrintButtonProps = {
  inspectionDetails: InspectionDetails;
  saveCurrentResponses: () => Promise<void>;
};

export const PrintButton: FC<PrintButtonProps> = ({
  inspectionDetails,
  saveCurrentResponses,
}) => {
  const navigate = useNavigate();

  const { setErrorMessage } = useToast();

  const answerTypes = useSelector(answersSlice.selectors.answerTypes);

  const handleOnClick = async () => {
    await saveCurrentResponses().catch(() =>
      setErrorMessage('Failed to save responses'),
    );

    navigate('/print-inspection', {
      state: {
        answerTypes,
        inspection: inspectionDetails,
      },
    });
  };

  return (
    <div className='sm:basis-1/4'>
      <Button onClick={() => void handleOnClick()} secondary>
        <PrinterIcon className='w-4 h-4 mr-1.5' /> Print
      </Button>
    </div>
  );
};
