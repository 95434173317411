import { useBreakpoints } from 'hooks/useBreakpoints';
import { Outlet } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';

import Header from './Header';
import SidebarDesktop from './SidebarDesktop';
import SidebarMobile from './SidebarMobile';
import { StickyMessages } from './StickyMessages';
import ToastContainer from './ToastContainer';

const Layout = () => {
  const { isMobile } = useBreakpoints();

  const [isSidebarOpen, setIsSidebarOpen] = useSessionStorage(
    'isSidebarOpen',
    true,
  );
  const toggleSidebar = () => {
    setIsSidebarOpen((pre) => !pre);
  };

  return (
    <>
      <ToastContainer />
      <div className='flex max-w-full h-svh bg-gray-100 *:overscroll-none'>
        {isMobile ? (
          <SidebarMobile isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        ) : (
          <SidebarDesktop
            isOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        )}
        <div className='flex flex-col grow overflow-auto bg-white'>
          <Header toggleSidebar={toggleSidebar} />
          <StickyMessages />
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default Layout;
