/** Generic no-op function. */
export const noOp = () => {
  void 0;
};

/** Alphabetical comparator by key */
export const alphabeticalCompare =
  <T>(keyExtractor: (item: T) => string) =>
  (lhs: T, rhs: T) => {
    return keyExtractor(lhs).localeCompare(keyExtractor(rhs));
  };

/**
 * Return true if any of the arrays contains an element
 * that is *not* in the other array, comparing elements by key.
 *
 * Can be used with various internal types, as several of them have a string ID.
 */
export const areDisjoint = <T extends { id: string }>(a: T[], b: T[]) => {
  if (a.length !== b.length) {
    return true;
  }
  return a.some((item) => !b.find((other) => item.id === other.id));
};

/**
 * Extend Partial to allow partial nested objects as well.
 */
export type DeepPartial<T> = T extends object
  ? { [P in keyof T]?: DeepPartial<T[P]> }
  : T;
