import { LocalDate } from '@js-joda/core';
import { Recurrence as TaskRecurrence } from 'components/recurrence';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Recurrence: FC = () => {
  const dispatch = useAppDispatch();

  const date = useSelector(taskEditSlice.selectors.date);

  /**
   * Dev note: at the moment, when creating a task and selecting a new due
   * date, the recurrence rule is reset to its default of "Does not repeat".
   * This is bad UX and it was accepted to leave it like that because the date
   * selection comes before the recurrence dialog. That said, this is likely to
   * change in the future, especially when recurring inspections are introduced
   * and this same logic is used in more places. We can prevent the reset of
   * the recurrence by removing the dependency on `date` in this memo call, and
   * ignoring the corresponding linter warning because we *only* want to
   * remember the initial date. However, the change is more delicate than that
   * because we are using UTC dates in some places instead of the local date
   * for the user, and that causes unwanted shifts in the initial selections
   * for the recurrence. Leaving this note here so that we know where to start
   * if and when we have to "fix" the resetting recurrence bug.
   */
  const initialDate = useMemo(() => LocalDate.parse(date), [date]);

  return (
    <>
      <label htmlFor='recurrence-option-selector'>Recurrence</label>
      <div>
        <TaskRecurrence
          initialDate={initialDate}
          onChange={(rule) =>
            dispatch(
              taskEditSlice.actions.setTaskField({
                field: 'recurrence',
                value: rule,
              }),
            )
          }
        />
      </div>
    </>
  );
};
