import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { FC } from 'react';

export const TaskLoadingAnimation: FC = () => (
  <div
    className={clsx(
      'w-full h-full flex flex-col gap-2 justify-center items-center',
      'text-lg text-gray-500 animate-pulse',
    )}
  >
    <ClipboardDocumentCheckIcon className='w-24 h-24' />
    Loading task...
  </div>
);
