import { Inspection, InspectionStatus } from '@dakota/platform-client';
import { PrinterIcon } from '@heroicons/react/20/solid';
import { Tooltip } from '@mui/material';
import Button from 'components/Button';
import { answersSlice } from 'features/answers/answersSlice';
import { configSlice } from 'features/config/configSlice';
import { getInspectionDetails } from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';

type Props = {
  inspection: Inspection;
};

export const Print: FC<Props> = ({ inspection }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const [loading, setLoading] = useState(false);

  const isDisabled =
    inspection.status !== InspectionStatus.InProgress &&
    inspection.status !== InspectionStatus.Completed;

  const answerTypes = useSelector(answersSlice.selectors.answerTypes);
  /**
   * Handles the click event for printing an inspection by fetching all
   * necessary data before redirecting to /print-inspection
   */
  const handlePrintClick = () => {
    setLoading(true);
    void dispatch(getInspectionDetails({ baseUrl, id: inspection.id, token }))
      .then(({ payload: inspectionDetails }) => {
        navigate('/print-inspection', {
          state: { answerTypes, inspection: inspectionDetails },
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Tooltip
      arrow
      placement='top'
      title={
        isDisabled
          ? "You cannot print an inspection until it's started"
          : 'Print Inspection'
      }
    >
      <div>
        <Button
          aria-label='Print Inspection'
          className='text-xs scale-90'
          data-testid='print-inspection-button'
          disabled={loading || isDisabled}
          icon={<PrinterIcon />}
          loading={loading}
          onClick={handlePrintClick}
        >
          Print
        </Button>
      </div>
    </Tooltip>
  );
};
