import { DatePickerRange } from 'components/DatePicker';

/**
 * Returns maxDate if endDate is over 365 days from beginDate, otherwise
 * returns undefined
 */
export const validateDateRange = (selectedDateRange: DatePickerRange) => {
  const endDate = selectedDateRange.end;
  const beginDate = selectedDateRange.begin;

  const maxDate = beginDate.plusDays(365);

  if (endDate.isAfter(maxDate)) {
    return maxDate;
  }
};
