import { AnswerType, ItemType, Question } from '@dakota/platform-client';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { AutocompleteRenderGroupParams } from '@mui/material';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import Input from 'components/Input';
import { MultilineInput } from 'components/MultilineInput';
import SidePanel from 'components/SidePanel';
import { WarningMessage } from 'components/WarningMessage';
import { answersSlice } from 'features/answers/answersSlice';
import { configSlice } from 'features/config/configSlice';
import { itemGroupsSlice } from 'features/items/itemGroupsSlice';
import { itemTypesSlice } from 'features/items/itemTypesSlice';
import { addQuestion, editQuestion } from 'features/questions/questionsActions';
import { questionsSlice } from 'features/questions/questionsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Errors } from 'types';
import { alphabeticalCompare } from 'utils/functional';

type Props = {
  isOpen: boolean;
  /**
   * If present, the component will work in edit mode instead of add
   */
  question?: Question;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const AddQuestions: FC<Props> = ({ isOpen, question, setIsOpen }) => {
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const dispatch = useAppDispatch();

  const itemGroups = useSelector(itemGroupsSlice.selectors.itemGroups);
  const itemTypes = useSelector(itemTypesSlice.selectors.itemTypes);
  const answerTypes = useSelector(answersSlice.selectors.answerTypes);
  const questions = useSelector(questionsSlice.selectors.allQuestions);

  const getGroupName = useCallback(
    (itemType: ItemType) =>
      itemGroups.find((g) => g.id === itemType.groupId)?.name ?? '',
    [itemGroups],
  );

  const selectableItemTypes = itemTypes
    .filter((i) => !i.inactive)
    .toSorted(
      (a, b) =>
        getGroupName(a).localeCompare(b.groupId) ||
        a.name.localeCompare(b.name),
    );

  const [selectedItemType, setSelectedItemType] = useState<ItemType | null>(
    null,
  );
  const [selectableAnswerTypes, setSelectableAnswerTypes] = useState<
    AnswerType[]
  >([]);
  const [selectedAnswerType, setSelectedAnswerType] =
    useState<AnswerType | null>(null);
  const [questionText, setQuestionText] = useState('');
  const [questionCitation, setQuestionCitation] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [hasDuplicateQuestion, setHasDuplicateQuestion] = useState(false);
  const initialRenderRef = useRef({ firstRender: true });

  const { setErrorMessage, setSuccessMessage } = useToast();

  const clearFormData = () => {
    if (!question) {
      // Only clear the form if we're adding a question
      setQuestionText('');
      setQuestionCitation('');
      setSelectedAnswerType(null);
      setSelectedItemType(null);
    }
  };

  useEffect(() => {
    const answerTypesArray: AnswerType[] = Array.from(answerTypes.values());
    setSelectableAnswerTypes(
      answerTypesArray.toSorted(alphabeticalCompare((e) => e.label)),
    );
  }, [answerTypes]);

  useEffect(() => {
    if (!question) {
      return;
    }
    const questionAnswerType = answerTypes.find(
      (a) => a.id === question.answerType.id,
    );
    const questionItemType = itemTypes.find(
      (itemType) => itemType.id === question.itemType.id,
    );

    setQuestionText(question.text);
    setQuestionCitation(question.citation ?? '');
    setSelectedItemType(questionItemType ?? null);
    setSelectedAnswerType(questionAnswerType ?? null);
  }, [answerTypes, itemGroups, itemTypes, question]);

  useEffect(() => {
    if (initialRenderRef.current.firstRender) {
      initialRenderRef.current.firstRender = false;
    }
  }, []);

  useEffect(() => {
    if (questionText.trim() && selectedItemType) {
      const isDuplicate = questions.some(
        (q) =>
          q.id !== question?.id &&
          q.text.toLowerCase() === questionText.trim().toLowerCase() &&
          q.itemType.id === selectedItemType.id,
      );
      setHasDuplicateQuestion(isDuplicate);
    } else {
      setHasDuplicateQuestion(false);
    }
  }, [questionText, selectedItemType, questions, question]);

  // Replace multiple new lines into one,
  // remove trailing new lines and trim whitespaces
  const sanitizedQuestionText = questionText.trim().replace(/(\n{2,})/g, '\n');

  const handleAddQuestionSuccess = () => {
    setIsOpen(false);
    setSuccessMessage('Added question successfully');
  };

  const handleAddQuestionFailure = () => {
    setErrorMessage('Failed to add question');
  };

  const handleEditSuccess = () => {
    setIsOpen(false);
    setSuccessMessage('Question edited successfully');
  };

  const handleEditFailure = () => {
    setIsOpen(false);
    setErrorMessage('Failed to edit question');
  };

  const submitHandler = () => {
    if (!selectedAnswerType || !selectedItemType || hasDuplicateQuestion) {
      return;
    }

    setIsProcessing(true);

    if (question) {
      dispatch(
        editQuestion({
          baseUrl,
          question: Question.fromJS({
            ...question,
            answerType: selectedAnswerType,
            citation: questionCitation.trim(),
            itemType: {
              group: itemGroups.find((g) => g.id === selectedItemType.groupId)
                ?.name,
              groupId: selectedItemType.groupId,
              id: selectedItemType.id,
              inactive: selectedItemType.inactive,
              isPlatformDefined: selectedItemType.isPlatformDefined,
              name: selectedItemType.name,
            },
            text: sanitizedQuestionText,
          }),
          token,
        }),
      )
        .unwrap()
        .then(() => {
          clearFormData();
          handleEditSuccess();
        })
        .catch(() => {
          handleEditFailure();
        })
        .finally(() => {
          setIsProcessing(false);
        });
      return;
    }
    dispatch(
      addQuestion({
        baseUrl,
        question: {
          answerType: { id: selectedAnswerType.id },
          citation: questionCitation.trim(),
          itemType: { id: selectedItemType.id },
          text: sanitizedQuestionText,
        } as Question,
        token,
      }),
    )
      .unwrap()
      .then(() => {
        clearFormData();
        handleAddQuestionSuccess();
      })
      .catch((error: unknown) => {
        if (error === Errors.DUPLICATE_QUESTION) {
          setErrorMessage('Question already exists');
          setHasDuplicateQuestion(true);
        } else {
          handleAddQuestionFailure();
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const cancelHandler = () => {
    if (!question) {
      // Clear the form only if we are adding a new question
      clearFormData();
    }
    setIsOpen(false);
  };

  const panelTitle = (
    <div className='flex text-green-base gap-2'>
      {question ? <PencilIcon className='w-6' /> : <PlusIcon className='w-6' />}
      <p className='text-lg font-medium'>Questions</p>
    </div>
  );

  let canFormBeSaved = !!(
    selectedAnswerType &&
    selectedItemType &&
    questionText.trim() &&
    !hasDuplicateQuestion
  );
  if (question) {
    canFormBeSaved &&=
      question.text !== questionText.trim() ||
      (question.citation ?? '') !== questionCitation.trim() ||
      selectedItemType?.id !== question.itemType.id;
  }

  const isChangingItemType =
    !!question && selectedItemType?.id !== question.itemType.id;

  const renderGroup = (params: AutocompleteRenderGroupParams) => (
    <div key={params.key}>
      <div className='p-2 italic text-sm font-semibold text-gray-700'>
        {params.group}
      </div>
      <div>{params.children}</div>
    </div>
  );

  return (
    <SidePanel
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      PanelTitle={panelTitle}
    >
      <div className='h-full flex flex-col justify-between'>
        <div className='flex-1 flex flex-col overflow-y-auto p-6 gap-6'>
          {isChangingItemType && (
            <WarningMessage variant='light'>
              <p>
                You are selecting a new Item Type.{' '}
                <strong>This action cannot be undone.</strong>
              </p>
              <p>
                After you save, we will start migrating existing templates that
                utilize this question to reflect the changes. The question will
                remain associated with its original Item Type until the process
                has completed.
              </p>
              <p>This may take a few minutes.</p>
            </WarningMessage>
          )}
          <div>
            <label
              className='text-gray-700 required-field'
              htmlFor='question-text'
              id='question-text-label'
            >
              Enter question
            </label>
            <MultilineInput
              aria-labelledby='question-text-label'
              data-testid='question-text'
              error={hasDuplicateQuestion ? 'Question already exists' : ''}
              id='question-text'
              maxRows={10}
              onChange={setQuestionText}
              required
              value={questionText}
            />
            {hasDuplicateQuestion && (
              <p className='text-red-base text-sm'>
                A question already exists for this Item Type with the same text.
                Please edit or remove this question.
              </p>
            )}
          </div>
          <Autocomplete
            className='flex-none w-64'
            getOptionKey={(itemType) => itemType.id}
            getOptionLabel={(itemType) => itemType.name}
            groupBy={getGroupName}
            id='select-item-type'
            isOptionEqualToValue={(a, b) => a.id === b.id}
            onChange={setSelectedItemType}
            options={selectableItemTypes}
            outsideLabel='Item Type'
            renderGroup={renderGroup}
            required
            value={selectedItemType}
          />
          <Input
            className='w-full'
            id='question-citation'
            label='Enter Citation'
            onChange={(e) => setQuestionCitation(e.target.value)}
            value={questionCitation}
          />
          <Autocomplete
            disabled={!!question}
            getOptionLabel={(option) =>
              option.answers.map((a) => a.answer).join(', ')
            }
            id='select-response-type'
            label='Select Response Type'
            onChange={setSelectedAnswerType}
            options={selectableAnswerTypes}
            outsideLabel='Response Type'
            required
            value={selectedAnswerType}
          />
        </div>
        <div className='flex-none h-16 flex gap-2.5 px-6 py-4 border-t border-gray-200'>
          <Button
            className={clsx('bg-green-base py-2 px-4 disabled:bg-gray-400', {
              'cursor-not-allowed': !canFormBeSaved,
            })}
            disabled={!canFormBeSaved || isProcessing}
            loading={isProcessing}
            onClick={submitHandler}
          >
            {isProcessing ? 'Saving...' : 'Save'}
          </Button>
          <Button
            className='py-2 px-4'
            disabled={isProcessing}
            onClick={cancelHandler}
            secondary
          >
            Cancel
          </Button>
        </div>
      </div>
    </SidePanel>
  );
};

export default AddQuestions;
