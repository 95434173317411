import Autocomplete from 'components/Autocomplete';
import { useCallback, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';

// The languages we support
const validLanguages = ['en', 'es'] as const;

export const useLocalePreference = () => {
  // Key to store preferred language in local storage
  const storageKey = 'dakota:locale';

  // The default locale to use if the user doesn't have a preference
  const defaultLocale = 'en-US';

  type ValidLanguage = (typeof validLanguages)[number];

  /**
   * Take a comma-separated list of locales and return
   * the first one that we support, or undefined if we don't support any.
   */
  const getSupportedLocale = (locales: string) => {
    try {
      // The user preference is a comma-separated list of preferred locales,
      // in order of preference. Let's find the first one with a language we
      // support.
      return locales
        .split(',')
        .find((locale) =>
          validLanguages.includes(
            new Intl.Locale(locale).language as ValidLanguage,
          ),
        );
    } catch (error: unknown) {
      console.warn('Could not process locale of value', error);
    }
  };

  /**
   * Get the name of each supported language *in that language*.
   * This is to display in a list of language options, where
   * the user expects to see their language, well, in their language.
   */
  const getLanguageName = useCallback((language: ValidLanguage) => {
    switch (language) {
      case 'en':
        return 'English';
      case 'es':
        return 'Español';
    }
  }, []);

  const [userLocale, setUserLocale] = useLocalStorage<string>(
    storageKey,
    getSupportedLocale(navigator.language) ?? defaultLocale,
    {
      deserializer: (s) => s,
      serializer: (s) => s,
    },
  );

  const userLanguage = useMemo(() => {
    const candidate = new Intl.Locale(userLocale).language;
    if (validLanguages.includes(candidate as ValidLanguage)) {
      return candidate as ValidLanguage;
    }
    return new Intl.Locale(defaultLocale).language as ValidLanguage;
  }, [userLocale]);

  const changeUserLocale = useCallback(
    (newValue: ValidLanguage) => {
      setUserLocale(newValue.toString());
      document.getElementById('language-select')?.blur();
    },
    [setUserLocale],
  );

  const ChangeLanguageDropdown = () => (
    <Autocomplete
      className='w-40 scale-90'
      getOptionLabel={getLanguageName}
      id='language-select'
      onChange={changeUserLocale}
      options={validLanguages}
      required
      value={userLanguage}
    />
  );

  return {
    ChangeLanguageDropdown,
    defaultLocale,
    /**
     * Used for all string translations.
     */
    userLanguage,
    /**
     * Used for date and number formatting.
     */
    userLocale,
  };
};
