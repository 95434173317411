import { configSlice } from 'features/config/configSlice';
import { getTaskDetails } from 'features/tasks/tasksActions';
import { tasksDetailsSlice } from 'features/tasks/tasksDetailsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { TaskDetails } from './TaskDetails';

type TaskLoaderProps = {
  date: string;
  onClose: () => void;
  seriesId: string;
};

/**
 * Take a task series ID and a date and load the task details.
 *
 * This component only fetches the TaskInstanceDetails object and opens the
 * actual TaskDetails component, which will decide whether to show the
 * read-only version or the editable version based on the permissions for the
 * user.
 *
 * In addition, the TaskDetails component is in charge of displaying an
 * animation while the task is being loaded, which can be determined by
 * checking whether the task instance details object is undefined.
 */
export const TaskLoader: FC<TaskLoaderProps> = ({
  date,
  onClose,
  seriesId,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const getTaskInstanceDetails = useSelector(
    tasksDetailsSlice.selectors.getTaskInstanceDetails,
  );
  const taskInstanceDetails = getTaskInstanceDetails(seriesId, date);

  useEffect(() => {
    if (!taskInstanceDetails) {
      void dispatch(
        getTaskDetails({
          baseUrl,
          date,
          seriesId,
          token,
        }),
      );
    }
  }, [baseUrl, dispatch, seriesId, date, taskInstanceDetails, token]);

  return <TaskDetails onClose={onClose} task={taskInstanceDetails} />;
};
