import PermissionGuard from 'auth/PermissionGuard';
import TabNavigation from 'components/TabNavigation';
import { useInternationalization } from 'hooks/useInternationalization';
import { NavLink, Outlet } from 'react-router-dom';
import { Permission } from 'utils/permissions';

const Organization = () => {
  const { t } = useInternationalization('layout.navigation.tabs');

  return (
    <div className={'h-full'}>
      <TabNavigation>
        <PermissionGuard permissions={Permission.CreateFacility}>
          <NavLink id='tabnav-facilities' to='facilities'>
            {t('facilities')}
          </NavLink>
        </PermissionGuard>
        <PermissionGuard permissions={Permission.ManageUsers}>
          <NavLink id='tabnav-users' to='users'>
            {t('users')}
          </NavLink>
        </PermissionGuard>
      </TabNavigation>
      <Outlet />
    </div>
  );
};

export default Organization;
