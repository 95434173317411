import { PrimitiveType, useIntl } from 'react-intl';

export const useInternationalization = (prefix?: string) => {
  const intl = useIntl();

  const t = (key: string, values?: Record<string, PrimitiveType>) => {
    return intl.formatMessage(
      { id: `${prefix?.concat('.') ?? ''}${key}` },
      values,
    );
  };

  return { t };
};
