import { TaskInstance, TaskState } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { useTaskEdit } from 'hooks/useTaskEdit';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { Permission } from 'utils/permissions';

import { getStatusLabel } from './types';

type StatusDropdownProps = {
  task: TaskInstance;
};

export const StatusDropdown: FC<StatusDropdownProps> = ({ task }) => {
  const { updateStatus } = useTaskEdit();

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [updatingStatus, setUpdatingStatus] = useState(false);
  const canUpdateTask = useCheckPermission(Permission.UpdateTask);

  const update = (status: TaskState) => {
    setUpdatingStatus(true);

    updateStatus(task, status)
      .then(() => setSuccessMessage('Task status updated successfully'))
      .catch(() => setErrorMessage('Failed to update task status'))
      .finally(() => setUpdatingStatus(false));
  };

  return (
    <Autocomplete
      className='min-w-36 bg-white rounded-md'
      disabled={!canUpdateTask}
      getOptionLabel={getStatusLabel}
      loading={updatingStatus}
      loadingText='Updating...'
      multiple={false}
      onChange={update}
      options={Object.values(TaskState)}
      testId={'task-status'}
      value={task.status}
    />
  );
};
