import { TaskInstanceDetails } from '@dakota/platform-client';
import { ClockIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';
import { clsx } from 'clsx';
import { toHumanReadable } from 'components/recurrence/humanReadable';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Attachments } from './EditTask/fields/Attachments';
import { buttonClasses } from './EditTask/fields/Buttons';
import { Notes } from './EditTask/fields/Notes';
import { Zone } from './EditTask/fields/Zone';
import { Header } from './EditTask/header';
import { getPriorityLabel, getStatusLabel } from './types';

type ViewTaskProps = {
  onClose: () => void;
  task: TaskInstanceDetails;
};

export const ViewTask: FC<ViewTaskProps> = ({ onClose, task }) => {
  const navigate = useNavigate();

  return (
    <div aria-label='View Task Panel' className='flex flex-col h-full'>
      <Header onClose={onClose} />
      <div
        className={clsx(
          'flex-1 flex flex-col gap-4 overflow-y-auto text-sm p-6 text-gray-700',
        )}
      >
        <div
          className={clsx(
            'flex flex-col gap-4',
            '*:flex *:items-center *:gap-4 *:min-h-9',
          )}
        >
          <div aria-label='Task title' className='justify-between text-lg'>
            {task.title}
            <button
              className={clsx(buttonClasses, '!h-9')}
              onClick={() => navigate('/print-task', { state: { task } })}
            >
              <PrinterIcon className='h-4' /> Print
            </button>
          </div>
          <div className='flex gap-1 items-center text-sm font-normal text-gray-700'>
            <ClockIcon className='w-6' />
            {toHumanReadable(task.seriesRecurrence.rule)}
          </div>
          <div
            aria-label='Task description'
            className={clsx(
              '!block text-sm font-normal text-balance whitespace-pre-line',
              'max-h-40 overflow-y-auto',
              task.description ? 'text-gray-700' : 'text-gray-400 italic',
            )}
          >
            {task.description ?? 'No description'}
          </div>
          <div>
            <div className='flex-1'>Status</div>
            <strong aria-label='Task status'>
              {getStatusLabel(task.status)}
            </strong>
          </div>
          <div>
            <div className='flex-1'>Facility</div>
            <strong aria-label='Task facility'>{task.facility.name}</strong>
          </div>
          <Zone />
          <div>
            <div className='flex-1'>Assignee</div>
            <strong
              aria-label='Task assignee'
              className={clsx({ 'italic text-gray-400': !task.assigneeId })}
            >
              {task.assigneeName ?? 'Unassigned'}
            </strong>
          </div>
          <div>
            <div className='flex-1'>Priority</div>
            <strong aria-label='Task priority'>
              {getPriorityLabel(task.priority)}
            </strong>
          </div>
          <div>
            <div className='flex-1'>Due Date</div>
            <strong aria-label='Task due date'>
              {LocalDate.parse(task.timeline.scheduledDate).format(
                DateTimeFormatter.ofPattern('MM/d/yyyy').withLocale(Locale.US),
              )}
            </strong>
          </div>
        </div>
        <div className='flex flex-col gap-4'>
          <Notes task={task} />
          <Attachments readonly task={task} />
        </div>
        <div
          className={clsx(
            'flex flex-col gap-4',
            '*:flex *:justify-stretch *:items-center *:h-9',
          )}
        >
          <div className='text-base font-bold'>Source</div>
          <div>
            <div className='flex-1'>Reported By</div>
            <strong aria-label='Task reported by'>{task.reportedBy}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};
