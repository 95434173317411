import {
  Inspection,
  InspectionStatus,
  SearchStatus,
  TaskInstance,
  TaskState,
} from '@dakota/platform-client';

import { AssignmentStatus } from './types';

/**
 * Determines the assignment status for an Inspection or TaskInstance
 * since we have two different status types.
 *
 * The logic assumes that in the assignments page, we only deal with
 * three statuses: In Progress, Overdue, and Scheduled.
 *
 * 1. First, the function checks if the item is Overdue. If so,
 *    it returns AssignmentStatus.Overdue.
 * 2. Next, it checks if the item is In Progress. If so,
 *    it returns AssignmentStatus.InProgress.
 * 3. Finally, if neither of the above conditions are met, we know
 *    the item must be Scheduled, because no other statuses are
 *    fetched on the assignments page.
 *
 * @param item - The item to evaluate (Inspection or TaskInstance).
 * @returns The AssignmentStatus value.
 */
export const getItemAssignmentStatus = (
  item: Inspection | TaskInstance,
): AssignmentStatus => {
  const isInspection = item instanceof Inspection;

  if (
    (isInspection && item.status === InspectionStatus.Overdue) ||
    (!isInspection && item.overdue)
  ) {
    return AssignmentStatus.Overdue;
  }

  if (
    (isInspection && item.status === InspectionStatus.InProgress) ||
    (!isInspection && item.status === TaskState.InProgress)
  ) {
    return AssignmentStatus.InProgress;
  }

  return AssignmentStatus.Scheduled;
};

export const getAssignmentStatusLabel = (status: AssignmentStatus) => {
  switch (status) {
    case AssignmentStatus.InProgress:
      return 'In Progress';
    case AssignmentStatus.Overdue:
      return 'Overdue';
    case AssignmentStatus.Scheduled:
      return 'Scheduled';
  }
};

export const mapToInspectionStatus = (
  statuses: AssignmentStatus[],
): InspectionStatus[] => {
  return statuses.map((status) => {
    switch (status) {
      case AssignmentStatus.InProgress:
        return InspectionStatus.InProgress;
      case AssignmentStatus.Overdue:
        return InspectionStatus.Overdue;
      case AssignmentStatus.Scheduled:
        return InspectionStatus.Scheduled;
    }
  });
};

export const mapToSearchStatus = (
  statuses: AssignmentStatus[],
): SearchStatus[] => {
  return statuses.map((status) => {
    switch (status) {
      case AssignmentStatus.InProgress:
        return SearchStatus.InProgress;
      case AssignmentStatus.Overdue:
        return SearchStatus.Overdue;
      case AssignmentStatus.Scheduled:
        return SearchStatus.Scheduled;
    }
  });
};
