import { clsx } from 'clsx';
import { FC, useEffect, useMemo, useState } from 'react';
import { RRule, Weekday } from 'rrule';

import { dayToString } from './rrule';

type WeekdaySelectionProps = {
  /**
   * The first day of the week in the selector.
   * @default RRule.SU
   */
  firstDay?: Weekday;
  /**
   * Days that are selected by default.
   * @default  firstDay
   */
  initialSelection?: Weekday[];
  /**
   * Callback that is called when the selection changes.
   */
  onChange: (days: Weekday[]) => void;
};

export const WeekdaySelection: FC<WeekdaySelectionProps> = ({
  firstDay = RRule.SU,
  initialSelection,
  onChange,
}) => {
  const [selectedDays, setSelectedDays] = useState<Weekday[]>(
    initialSelection ?? [firstDay],
  );

  useEffect(
    () => setSelectedDays(initialSelection ?? [firstDay]),
    [firstDay, initialSelection],
  );

  const days = useMemo(() => {
    const weekdays = [
      RRule.MO,
      RRule.TU,
      RRule.WE,
      RRule.TH,
      RRule.FR,
      RRule.SA,
      RRule.SU,
    ];
    const index = weekdays.indexOf(firstDay);
    return [...weekdays.slice(index), ...weekdays.slice(0, index)];
  }, [firstDay]);

  const toggleDay = (day: Weekday) => {
    if (selectedDays.some((d) => d.equals(day)) && selectedDays.length > 1) {
      const newSelection = selectedDays.filter((d) => !d.equals(day));
      setSelectedDays(newSelection);
      onChange(newSelection);
    } else if (!selectedDays.includes(day)) {
      const newSelection = [...selectedDays, day];
      setSelectedDays(newSelection);
      onChange(newSelection);
    }
  };

  const classes = clsx(
    'p-2 self-center bg-gray-100 hover:bg-gray-200',
    'first-of-type:rounded-l-md last-of-type:rounded-r-md',
    'aria-pressed:bg-green-base aria-pressed:text-white',
    'aria-pressed:hover:bg-green-dark',
  );

  return (
    <div className='inline-flex text-xs text-green-base'>
      {days.map((day) => (
        <button
          aria-label={dayToString(day)}
          aria-pressed={selectedDays.some((s) => s.equals(day))}
          className={classes}
          key={day.toString()}
          onClick={() => toggleDay(day)}
        >
          {day.toString().charAt(0)}
        </button>
      ))}
    </div>
  );
};
