import { TaskInstanceDetails } from '@dakota/platform-client';
import SidePanel from 'components/SidePanel';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Permission } from 'utils/permissions';

import { EditTask } from './EditTask';
import { TaskLoadingAnimation } from './TaskLoadingAnimation';
import { ViewTask } from './ViewTask';

type TaskDetailsProps = {
  onClose: () => void;
  task?: TaskInstanceDetails;
};

export const TaskDetails: FC<TaskDetailsProps> = ({ onClose, task }) => {
  const currentUser = useSelector(userSlice.selectors.currentUser);
  const hasAdminPermission = useCheckPermission(Permission.Admin);
  const hasEditTaskPermission = useCheckPermission(Permission.UpdateTask);
  /**
   * Who can edit a task:
   * - Admins
   * - Users with "edit task" permission AND:
   *   - The assignee of the task is the user, OR the task is unassigned
   */
  const canEditTask =
    hasAdminPermission ||
    (hasEditTaskPermission &&
      (task?.assigneeId === currentUser.id || task?.assigneeId === undefined));

  return (
    <SidePanel isOpen onClose={onClose} size='lg'>
      {task && canEditTask && <EditTask onClose={onClose} task={task} />}
      {task && !canEditTask && <ViewTask onClose={onClose} task={task} />}
      {!task && (
        <div className='flex justify-center items-center h-full'>
          <TaskLoadingAnimation />
        </div>
      )}
    </SidePanel>
  );
};
