import {
  FacilityAccessSummary,
  UserDetails,
  UserProfile,
  UserSummary,
} from '@dakota/platform-client';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DakotaUser } from 'auth/DakotaUser';
import { UserTracker } from 'telemetry/userTracker';
import { toIdentity } from 'telemetry/util';
import { toUserSummary } from 'utils/user';

import { defaultUser } from './defaultUser';
import {
  activateUser,
  addUser,
  deactivateUser,
  deleteUser,
  getAllUsers,
  getAllUserSummaries,
  getCurrentUser,
  listActiveUsersForActiveFacilities,
  listFacilityUsers,
  resendInvitation,
  resetPassword,
  testUserAccess,
  updatePhoneNumber,
  updateUserWithAllFields,
} from './userActions';

export type UserState = {
  /**
   * The list of all users for the tenant.
   */
  allUsers: UserDetails[];
  /**
   * The list of all users for the tenant that have
   * common facility access with the logged-in user.
   */
  allUserSummaries: UserSummary[];
  /**
   * The currently logged-in user.
   */
  currentUser: UserProfile;
  /**
   * When `true`, we know the user got a '403 Forbidden' response
   * from a backend API call, which means they should be logged out.
   *
   * This flag is checked in the App component. As soon as the user
   * gets the flag sets to `true`, they lose access to the app entirely.
   */
  hasForbiddenResponse: boolean;
  /**
   * True while the backend call to resend an invite is in progress.
   */
  isResendingInvitation: boolean;
  /**
   * True while the backend call to reset a user's password is in progress.
   */
  isResettingPassword: boolean;
  /**
   * Whether the user is currently updating their phone number.
   */
  isUpdatingPhoneNumber: boolean;
  /**
   * Whether the user is currently being updated.
   */
  isUpdatingUser: boolean;
  /**
   * List of users for each facility.
   */
  usersPerFacility: Map<string, UserSummary[]>;
};

const initialState: UserState = {
  allUsers: [],
  allUserSummaries: [],
  currentUser: defaultUser,
  hasForbiddenResponse: false,
  isResendingInvitation: false,
  isResettingPassword: false,
  isUpdatingPhoneNumber: false,
  isUpdatingUser: false,
  usersPerFacility: new Map(),
};

export const userSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(updatePhoneNumber.pending, (state) => {
      state.isUpdatingPhoneNumber = true;
    });
    builder.addCase(updatePhoneNumber.rejected, (state) => {
      state.isUpdatingPhoneNumber = false;
    });
    builder.addCase(updatePhoneNumber.fulfilled, (state, action) => {
      state.isUpdatingPhoneNumber = false;
      state.currentUser = action.payload;
    });
    builder.addCase(updateUserWithAllFields.pending, (state) => {
      state.isUpdatingUser = true;
    });
    builder.addCase(updateUserWithAllFields.rejected, (state) => {
      state.isUpdatingUser = false;
    });
    builder.addCase(updateUserWithAllFields.fulfilled, (state, action) => {
      state.isUpdatingUser = false;
      const user = action.payload;
      if (user.id === state.currentUser.id) {
        state.currentUser = user;
      }
      state.allUsers = state.allUsers.map((u) => (u.id === user.id ? user : u));
      state.allUserSummaries = state.allUserSummaries.map((u) =>
        u.id === user.id ? toUserSummary(user) : u,
      );
      userSlice.caseReducers.removeUserFromAllFacilities(state, action);
      userSlice.caseReducers.addUserToAssignedFacilities(state, action);
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload;
      // Note this is the one place where we don't update the summaries
      // as well as the full users, because the APIs that return the full
      // lists have slightly different returns (not just the types, but
      // also which users they return in terms of permissions).
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
    builder.addCase(testUserAccess.rejected, (state, action) => {
      // Check for our internal payload for a 403 response from the backend
      if (action.payload === 403) {
        state.hasForbiddenResponse = true;

        // As a minor security measure, we store the forbidden state in
        // local storage, so that if the user refreshes the page
        // we can still show the 'forbidden' message.
        // This is by no means foolproof, but it prevents some cases where
        // the user might try to go back to a previous page or refresh
        // to bypass the check.
        localStorage.setItem('isForbidden', 'true');
      }
    });
    builder.addCase(listFacilityUsers.fulfilled, (state, action) => {
      state.usersPerFacility.set(
        action.payload.facilityId,
        action.payload.users,
      );
    });
    builder.addCase(
      listActiveUsersForActiveFacilities.fulfilled,
      (state, action) => {
        action.payload.forEach((facility: FacilityAccessSummary) => {
          state.usersPerFacility.set(facility.id, facility.users);
        });
      },
    );
    builder.addCase(addUser.fulfilled, (state, action) => {
      const user = action.payload;
      state.allUsers = [...state.allUsers, user];
      state.allUserSummaries = [...state.allUserSummaries, toUserSummary(user)];
      if (!user.inactive) {
        userSlice.caseReducers.addUserToAssignedFacilities(state, action);
      }
    });
    builder.addCase(activateUser.fulfilled, (state, action) => {
      const user = action.payload;
      state.allUsers = state.allUsers.map((u) =>
        u.id === user.id ? { ...u, inactive: false } : u,
      );
      state.allUserSummaries = state.allUserSummaries.map((u) =>
        u.id === user.id ? { ...u, inactive: false } : u,
      );
      userSlice.caseReducers.addUserToAssignedFacilities(state, action);
    });
    builder.addCase(deactivateUser.fulfilled, (state, action) => {
      const user = action.payload;
      state.allUsers = state.allUsers.map((u) =>
        u.id === user.id ? { ...u, inactive: true } : u,
      );
      state.allUserSummaries = state.allUserSummaries.map((u) =>
        u.id === user.id ? { ...u, inactive: true } : u,
      );
      userSlice.caseReducers.removeUserFromAllFacilities(state, action);
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      const user = action.payload;
      state.allUsers = state.allUsers.filter((u) => u.id !== user.id);
      state.allUserSummaries = state.allUserSummaries.filter(
        (u) => u.id !== user.id,
      );
      userSlice.caseReducers.removeUserFromAllFacilities(state, action);
    });
    builder.addCase(resendInvitation.pending, (state) => {
      state.isResendingInvitation = true;
    });
    builder.addCase(resendInvitation.fulfilled, (state) => {
      state.isResendingInvitation = false;
    });
    builder.addCase(resendInvitation.rejected, (state) => {
      state.isResendingInvitation = false;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.isResettingPassword = true;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.isResettingPassword = false;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.isResettingPassword = false;
    });
    builder.addCase(getAllUserSummaries.fulfilled, (state, action) => {
      state.allUserSummaries = action.payload;
    });
  },
  initialState,
  name: 'user',
  reducers: {
    /**
     * Add the user to the facilities they have access to, in the
     * current mapping from facility ID to list of users.
     */
    addUserToAssignedFacilities(state, action: PayloadAction<UserDetails>) {
      const user = action.payload;
      state.usersPerFacility.forEach((users, facilityId) => {
        if (
          user.facilities.direct.some((f) => f.id === facilityId) ||
          user.facilities.implicit.some((f) => f.id === facilityId)
        ) {
          state.usersPerFacility.set(facilityId, [
            ...users,
            toUserSummary(user),
          ]);
        }
      });
    },
    logoutUser() {
      UserTracker.clearUser();
    },
    /**
     * Remove the user from all facilities they have access to in the
     * current mapping from facility ID to list of users.
     */
    removeUserFromAllFacilities(state, action: PayloadAction<UserDetails>) {
      state.usersPerFacility.forEach((users, facilityId) => {
        state.usersPerFacility.set(
          facilityId,
          users.filter((u) => u.id !== action.payload.id),
        );
      });
    },
    setUser(_state, action: PayloadAction<DakotaUser>) {
      UserTracker.setUser(toIdentity(action.payload));
    },
  },
  selectors: {
    allUsers: (state: UserState) => state.allUsers,
    allUserSummaries: (state: UserState) => state.allUserSummaries,
    currentUser: (state: UserState) => state.currentUser,
    getUsersPerFacility: createSelector(
      (state: UserState) => state.usersPerFacility,
      (usersPerFacility) => (facilityId: string | undefined) =>
        usersPerFacility.get(facilityId ?? '') ?? [],
    ),
    hasForbiddenResponse: (state: UserState) => state.hasForbiddenResponse,
    isResendingInvitation: (state: UserState) => state.isResendingInvitation,
    isResettingPassword: (state: UserState) => state.isResettingPassword,
    isUpdatingPhoneNumber: (state: UserState) => state.isUpdatingPhoneNumber,
    isUpdatingUser: (state: UserState) => state.isUpdatingUser,
  },
});

export const { logoutUser, setUser } = userSlice.actions;
