import { ResponsesReport } from '@dakota/platform-client';
import { reportsSlice } from 'features/reports/reportsSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import ChartContainer from './ChartContainer';
import DashboardPieChart from './DashboardPieChart';

export const ResultsChart: FC = () => {
  const { t } = useInternationalization('charts');

  const responsesReport = useSelector(reportsSlice.selectors.responsesReport);
  const isLoadingResponsesReport = useSelector(
    reportsSlice.selectors.isLoadingResponsesReport,
  );

  const byResults = (report: ResponsesReport): (number | string)[][] => {
    const { fail, notApplicable, pass, warning } = report;

    /**
     * Data for the 'Breakdown by Results' chart *MUST* be passed
     * in the following order: Pass, Warning, Fail, N/A.
     */
    return [
      ['Status', 'Value'],
      ['Pass', pass],
      ['Warning', warning],
      ['Fail', fail],
      ['N/A', notApplicable],
    ];
  };

  return (
    <ChartContainer
      content={
        <DashboardPieChart
          data={responsesReport ? byResults(responsesReport) : []}
          loading={isLoadingResponsesReport}
          type='results'
        />
      }
      title={t('inspections.breakdown.by.results')}
    />
  );
};
