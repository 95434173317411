import {
  Facility,
  FormSummary,
  FormType,
  Summary,
} from '@dakota/platform-client';
import { PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { GridColDef } from '@mui/x-data-grid';
import PermissionGuard from 'auth/PermissionGuard';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import Chip from 'components/Chip';
import { ClearAllButton } from 'components/ClearAll';
import Schedule from 'components/Inspections/Schedule';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { Table } from 'components/Table';
import { TemplatesMenu } from 'components/TemplatesMenu';
import Tooltip from 'components/Tooltip';
import { configSlice } from 'features/config/configSlice';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { getAllFormSummaries } from 'features/forms/formsActions';
import { formsSlice } from 'features/forms/formsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import Fuse from 'fuse.js';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import FormBuilderStepperDialog from 'Pages/FormBuilder/stepper/stepperDialog';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { formatBackendDateTime } from 'utils/date';
import { getHighlighter } from 'utils/highlighter';
import { Permission } from 'utils/permissions';

const InspectionsTemplates = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const allForms = useSelector(formsSlice.selectors.forms);
  const selectableFacilities = useSelector(
    facilitiesSlice.selectors.activeFacilities,
  );
  const isAdminUser = useCheckPermission(Permission.Admin);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('Active');
  const [selectedArea, setSelectedArea] = useState('All');
  const [filteredForms, setFilteredForms] = useState<FormSummary[]>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);
  const [isFormBuilderOpen, setIsFormBuilderOpen] = useState(false);

  const { stopTracking } = usePageLoadTracking();

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    let newForms = allForms;

    if (selectedStatus === 'Active') {
      newForms = newForms.filter((f) => !f.inactive);
    } else if (selectedStatus === 'Inactive') {
      newForms = newForms.filter((f) => f.inactive);
    }

    if (selectedArea === 'Global') {
      newForms = newForms.filter((f) => f.type === FormType.Global);
    } else if (selectedArea === 'Facility') {
      newForms = newForms.filter((f) => f.type === FormType.Facility);
    }

    let selectedFacilityForms = [];
    let globalForms = [];

    if (selectedFacilities.length > 0) {
      selectedFacilityForms = newForms.filter(
        (form) =>
          form.type !== FormType.Global &&
          selectedFacilities.find(
            (facility) => form.facility?.id === facility.id,
          ),
      );
      globalForms = newForms.filter((form) => form.type === FormType.Global);
      newForms = [...selectedFacilityForms, ...globalForms];
    }

    if (searchTerm) {
      const fuse = new Fuse(newForms, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: ['name'],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newForms = fuse.search(searchTerm).map((e) => e.item);
    }

    setFilteredForms(newForms);
  }, [allForms, searchTerm, selectedArea, selectedFacilities, selectedStatus]);

  const resetFilters = () => {
    setSearchTerm('');
    setSelectedFacilities([]);
    setSelectedStatus('Active');
    setSelectedArea('All');
  };

  useEffect(() => {
    void dispatch(getAllFormSummaries({ baseUrl, token })).then(() =>
      stopTracking(DataStatus.Fetched),
    );
  }, [token, dispatch, baseUrl, stopTracking]);

  const removeFacilityChip = (facility: Summary) => {
    setSelectedFacilities((prev) => prev.filter((f) => f.id !== facility.id));
  };

  const inactiveCls = (inactive: boolean) =>
    clsx({ 'text-gray-400': inactive });

  const highlight = getHighlighter(searchTerm);
  const canSchedule = useCheckPermission(Permission.CreateInspection);

  let templateColumns: GridColDef<FormSummary>[] = [
    {
      display: 'flex',
      field: 'title',
      flex: 1,
      headerName: 'Title',
      minWidth: 250,
      renderCell: (params) => {
        const template = params.row;
        return (
          <div className={inactiveCls(template.inactive)}>
            <div className='font-semibold'>{highlight(template.name)}</div>
            <div className='text-xs font-medium leading-tight text-gray-400'>
              <Tooltip
                arrow
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                title={
                  template.lastUsed
                    ? 'The last inspection completed using this template was on ' +
                      formatBackendDateTime(template.lastUsed, false)
                    : 'No inspections have been completed using this template'
                }
              >
                <div className='inline-flex items-center gap-1'>
                  {template.lastUsed
                    ? 'Last used: ' +
                      formatBackendDateTime(template.lastUsed, false)
                    : 'Not used'}
                  <QuestionMarkCircleIcon className='w-4 h-4' />
                </div>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    {
      field: 'description',
      flex: 1,
      headerName: 'Description',
      minWidth: 250,
      renderCell: (params) => (
        <div className={clsx(inactiveCls(params.row.inactive), 'truncate')}>
          {params.row.description}
        </div>
      ),
    },
    {
      align: 'center',
      field: 'type',
      headerAlign: 'center',
      headerName: 'Area',
      renderCell: (params) => {
        const template = params.row;
        if (template.type === FormType.Global) {
          return <div className={inactiveCls(template.inactive)}>Global</div>;
        } else {
          return (
            <div className={inactiveCls(template.inactive)}>
              Facility
              <div className='max-sm:hidden text-xs font-medium leading-tight'>
                {template.facility?.name}
              </div>
            </div>
          );
        }
      },
      width: 80,
    },
    {
      align: 'center',
      field: 'inactive',
      headerAlign: 'center',
      headerName: 'Status',
      renderCell: (params) => {
        return (
          <div className={inactiveCls(params.row.inactive)}>
            {params.row.inactive ? 'Inactive' : 'Active'}
          </div>
        );
      },
      width: 80,
    },
    {
      align: 'center',
      display: 'flex',
      field: 'schedule',
      headerAlign: 'center',
      headerName: 'Schedule',
      renderCell: (params) => (
        <div>
          <Schedule form={params.row} key={params.row.id} />
        </div>
      ),
      resizable: false,
      width: 120,
    },
    {
      display: 'flex',
      field: 'menu',
      headerName: '',
      renderCell: (params) => (
        <div className='hidden sm:block'>
          <PermissionGuard
            permissions={[
              Permission.CreateGlobalForm,
              Permission.CreateFacilityForm,
            ]}
          >
            <TemplatesMenu template={params.row} />
          </PermissionGuard>
        </div>
      ),
      resizable: false,
      width: 50,
    },
  ];

  if (!isAdminUser) {
    templateColumns = templateColumns.filter((c) => c.field !== 'inactive');
  }
  if (!canSchedule) {
    templateColumns = templateColumns.filter((c) => c.field !== 'schedule');
  }

  return (
    <div className='p-4 sm:p-8' data-testid='templates'>
      <PageHeader
        scaffold={['Inspection Templates', 'Manage Content']}
        title='Templates'
      >
        <PermissionGuard
          permissions={[
            Permission.CreateGlobalForm,
            Permission.CreateFacilityForm,
          ]}
        >
          <Button
            className='px-4 py-2 bg-green-base'
            icon={<PlusIcon />}
            id='add-template-button'
            onClick={() => setIsFormBuilderOpen(true)}
          >
            Add Template
          </Button>
        </PermissionGuard>
      </PageHeader>
      <div className='filters-container'>
        <SearchInput
          containerClasses='sm:w-60'
          id='inspection-templates-search-input'
          onSearch={handleSearch}
          placeholder='Search by template title'
          value={searchTerm}
        />
        <Autocomplete
          className='w-full sm:w-[238px] h-[38px]'
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          id='inspection-templates-facility-selector'
          label='Facilities'
          multiple
          onChange={setSelectedFacilities}
          options={selectableFacilities}
          value={selectedFacilities}
        />
        <div className='hidden sm:block'>
          <Autocomplete
            className='w-28'
            id='inspection-templates-area-selector'
            label='Area'
            onChange={(value) => setSelectedArea(value)}
            options={['All', 'Global', 'Facility']}
            value={selectedArea}
          />
        </div>
        {isAdminUser && (
          <div className='hidden sm:block'>
            <Autocomplete
              className='w-28'
              id='inspection-templates-status-selector'
              label='Status'
              onChange={(value) => setSelectedStatus(value)}
              options={['All', 'Active', 'Inactive']}
              value={selectedStatus}
            />
          </div>
        )}
        {(selectedFacilities.length > 0 ||
          selectedStatus !== 'Active' ||
          selectedArea !== 'All' ||
          searchTerm.length > 0) && <ClearAllButton onClick={resetFilters} />}
      </div>
      {selectedFacilities.length > 0 && (
        <div className='flex items-center gap-2 pb-2'>
          <span className='text-sm font-normal pr-1'>Facilities:</span>
          {selectedFacilities.map((facility) => (
            <Chip
              key={facility.id}
              onRemove={() => removeFacilityChip(facility)}
              testId='facility-chip'
              text={facility.name}
            />
          ))}
        </div>
      )}
      <Table
        columns={templateColumns}
        loading={useSelector(formsSlice.selectors.isLoadingForms)}
        rows={filteredForms}
      />
      {isFormBuilderOpen && (
        <FormBuilderStepperDialog onClose={() => setIsFormBuilderOpen(false)} />
      )}
    </div>
  );
};

export default InspectionsTemplates;
