import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import es from 'javascript-time-ago/locale/es';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Root } from 'root';

import './index.css';

TimeAgo.addLocale(es);
TimeAgo.addDefaultLocale(en);

const rootElement = document.getElementById('root') as Element;
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
);
