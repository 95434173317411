import { clsx } from 'clsx';
import Tooltip from 'components/Tooltip';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { FC, PropsWithoutRef, RefAttributes, SVGProps } from 'react';
import { NavLink as ReactNavLink, To } from 'react-router-dom';

type HeroIconSVGProps = PropsWithoutRef<SVGProps<SVGSVGElement>> &
  RefAttributes<SVGSVGElement>;
type HeroIconProps = {
  title?: string;
  titleId?: string;
} & HeroIconSVGProps;

// See following discussion for reference:
// https://github.com/tailwindlabs/heroicons/discussions/981#discussioncomment-5621473
type HeroIcon = FC<HeroIconProps>;

type Props = {
  icon: HeroIcon;
  id?: string;
  isSidebarOpen: boolean;
  title: string;
  to: To;
};

const NavLink: FC<Props> = ({ icon: Icon, id, isSidebarOpen, title, to }) => {
  const { isMobile } = useBreakpoints();

  return (
    <ReactNavLink
      aria-label={title}
      className={({ isActive }) =>
        clsx(
          'flex items-center transition p-3 gap-3 rounded-lg text-gray-800',
          'hover:bg-gray-200 w-full',
          {
            'active bg-green-base text-white hover:bg-green-dark': isActive,
            'justify-center': !isMobile && !isSidebarOpen,
            'w-14 aspect-square': !isSidebarOpen,
          },
        )
      }
      id={id}
      to={to}
    >
      {isSidebarOpen || isMobile ? (
        <>
          <Icon width={24} />
          <p className='text-sm font-medium'>{title}</p>
        </>
      ) : (
        <Tooltip placement='right' title={title}>
          <Icon width={24} />
        </Tooltip>
      )}
    </ReactNavLink>
  );
};

export default NavLink;
