import { Bars4Icon, TableCellsIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Tooltip from 'components/Tooltip';
import { useInternationalization } from 'hooks/useInternationalization';
import React from 'react';

type ViewToggleProps = {
  listView: boolean;
  setListView: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ViewToggle: React.FC<ViewToggleProps> = ({
  listView,
  setListView,
}) => {
  const { t } = useInternationalization('components.view.toggle');

  const switchView = () => {
    setListView((prev) => !prev);
  };

  const buttonClasses = clsx(
    'w-5 h-5 text-gray-300',
    'hover:text-gray-700 disabled:text-gray-700',
  );
  return (
    <div className='hidden sm:inline-flex items-center gap-2'>
      <div className='flex gap-2'>
        <Tooltip arrow disabled={listView} title={t('list.view')}>
          <button
            aria-label={t('list.view')}
            className={buttonClasses}
            disabled={listView}
            onClick={switchView}
          >
            <Bars4Icon />
          </button>
        </Tooltip>
        <Tooltip arrow disabled={!listView} title={t('card.view')}>
          <button
            aria-label={t('card.view')}
            className={buttonClasses}
            disabled={!listView}
            onClick={switchView}
          >
            <TableCellsIcon />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};
