import { TablePagination } from '@mui/material';
import {
  DataGridProps,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { getRowIdFromRowModel } from '@mui/x-data-grid/internals';
import { clsx } from 'clsx';
import NoData from 'components/NoData';
import { Spinner } from 'components/Spinner';
import { Fragment, useEffect, useState } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';
const { theme } = resolveConfig(tailwindConfig);

export interface MobileTableProps<T extends GridValidRowModel>
  extends DataGridProps<T> {
  columns: readonly ({ order?: number } & GridColDef<T>)[];
}

/**
 * Comparator to sort columns by their `order` prop. Columns with
 * `undefined` order maintain their relative order, but are displayed
 * after all columns with a numeric order.
 */
const columnComparator = (lhs: { order?: number }, rhs: { order?: number }) => {
  if (lhs.order === undefined && rhs.order === undefined) {
    return 0;
  }
  if (lhs.order === undefined) {
    return 1;
  }
  if (rhs.order === undefined) {
    return -1;
  }
  return lhs.order - rhs.order;
};

export const MobileTable = <T extends GridValidRowModel>({
  columns,
  loading = false,
  rows,
}: MobileTableProps<T>) => {
  const sortedColumns = columns.toSorted(columnComparator);

  // For the mobile view
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const totalElements = rows?.length ?? 0;
  const totalPages = Math.ceil(totalElements / pageSize);

  // The number of rows change when filters are applied,
  // at which point we go back to the first page
  useEffect(() => setPage(0), [rows?.length]);

  if (loading || !rows || rows.length === 0) {
    return (
      <div
        className={clsx(
          'sm:hidden p-3 border border-gray-200 rounded-lg',
          'hover:border-gray-500 text-gray-400',
          'flex justify-center items-center',
        )}
      >
        {loading && <Spinner />}
        {!loading && rows?.length === 0 && <NoData />}
      </div>
    );
  }

  return (
    <>
      {totalPages > 1 && (
        <div className='flex justify-center sticky top-0 bg-white'>
          <TablePagination
            component='div'
            count={totalElements}
            onPageChange={(_, value) => setPage(value)}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[]}
            sx={{
              '& .MuiTablePagination-displayedRows': {
                color: theme.colors.gray[700],
              },
            }}
          />
        </div>
      )}
      <div className='flex flex-col gap-4' role='grid'>
        {rows
          .slice(page * pageSize, (page + 1) * pageSize)
          .map((row, index) => {
            return (
              <div className='group' key={getRowIdFromRowModel(row)} role='row'>
                <div className='flex justify-end pe-2 text-xs italic'>
                  <div
                    className={clsx(
                      'px-2 bg-gray-100 rounded-t text-gray-400',
                      'group-hover:bg-gray-400 group-hover:text-gray-50',
                    )}
                  >
                    {page * pageSize + index + 1}
                  </div>
                </div>
                <div
                  className={clsx(
                    'p-3 border border-gray-100 rounded-lg',
                    'group-hover:border-gray-400 text-gray-700 text-sm',
                    'grid grid-cols-3 gap-2 items-center group-hover:bg-gray-50',
                  )}
                >
                  {sortedColumns.map((column) => (
                    <Fragment key={column.field}>
                      <div className='text-gray-400'>{column.headerName}</div>
                      <div className='col-span-2 overflow-x-auto text-pretty'>
                        {column.renderCell
                          ? column.renderCell({
                              row,
                            } as GridRenderCellParams<T>)
                          : row[column.field]}
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
