import { OnTimeRatioReport } from '@dakota/platform-client';
import Tooltip from 'components/Tooltip';
import { useInternationalization } from 'hooks/useInternationalization';
import { AssignmentType } from 'Pages/Assignments/types';
import { useMemo } from 'react';

import SummaryCard from './SummaryCard';

type Props = {
  onTimeRatioReport: OnTimeRatioReport;
  selectedType: AssignmentType;
};

export const CompletedOnTime = ({ onTimeRatioReport, selectedType }: Props) => {
  const { t } = useInternationalization('charts');

  const inspectionsDue = onTimeRatioReport.due.inspections;
  const tasksDue = onTimeRatioReport.due.tasks;
  const totalDue = inspectionsDue + tasksDue;

  const inspectionOnTimeCount =
    onTimeRatioReport.completed.inspections +
    onTimeRatioReport.canceled.inspections;

  const tasksOnTimeCount =
    onTimeRatioReport.completed.tasks + onTimeRatioReport.canceled.tasks;

  const totalOnTimeCount = inspectionOnTimeCount + tasksOnTimeCount;

  const tooltipContent = useMemo(() => {
    if (
      totalDue === 0 ||
      (selectedType === AssignmentType.Inspection && inspectionsDue === 0) ||
      (selectedType === AssignmentType.Task && tasksDue === 0)
    ) {
      return <div aria-label={t('completed.on.time')}>{t('nothing.due')}</div>;
    }

    switch (selectedType) {
      case AssignmentType.All:
        return (
          <div
            aria-label={t('completed.on.time')}
            className='grid grid-cols-3 even:*:text-right odd:*:col-span-2'
          >
            <div className='font-medium'>Total</div>
            <div className='font-medium'>
              {totalOnTimeCount} / {totalDue}
            </div>
            <div>Inspections</div>
            <div>
              {inspectionOnTimeCount} / {inspectionsDue}
            </div>
            <div>Tasks</div>
            <div>
              {tasksOnTimeCount} / {tasksDue}
            </div>
          </div>
        );
      case AssignmentType.Inspection:
        return (
          <div aria-label={t('completed.on.time')}>
            Inspections: {inspectionOnTimeCount} / {inspectionsDue}
          </div>
        );
      case AssignmentType.Task:
        return (
          <div aria-label={t('completed.on.time')}>
            Tasks: {tasksOnTimeCount} / {tasksDue}
          </div>
        );
    }
  }, [
    inspectionOnTimeCount,
    inspectionsDue,
    selectedType,
    t,
    tasksDue,
    tasksOnTimeCount,
    totalDue,
    totalOnTimeCount,
  ]);

  const completedPercentage = useMemo(() => {
    let percentage;
    switch (selectedType) {
      case AssignmentType.All:
        percentage = (totalOnTimeCount / totalDue) * 100;
        break;
      case AssignmentType.Inspection:
        percentage = (inspectionOnTimeCount / inspectionsDue) * 100;
        break;
      case AssignmentType.Task:
        percentage = (tasksOnTimeCount / tasksDue) * 100;
        break;
    }

    // If the percentage is NaN, return `undefined` to display 'N/A' in the card
    return isNaN(percentage) ? undefined : Math.round(percentage);
  }, [
    inspectionOnTimeCount,
    inspectionsDue,
    selectedType,
    tasksDue,
    tasksOnTimeCount,
    totalDue,
    totalOnTimeCount,
  ]);

  return (
    <Tooltip arrow title={tooltipContent}>
      <div>
        <SummaryCard
          bgBreakpoints={[50, 80]}
          title={t('completed.on.time')}
          type='percent'
          value={completedPercentage}
        />
      </div>
    </Tooltip>
  );
};
