import { FilesClient, SecuredFileParams } from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const getFile = createAsyncThunk(
  'files/getFile',
  async (params: { attachmentId: string } & ClientData & SecuredFileParams) => {
    const client = getClient(FilesClient, params);

    const response = await client.getSecuredFile(params);
    return response.result;
  },
);
