import { Facility } from '@dakota/platform-client';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import Autocomplete from 'components/Autocomplete';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';

const FacilityChoice: FC<{
  facility: Facility | null;
  onChange: (facility: Facility) => void;
}> = ({ facility, onChange }) => {
  const activeFacilities = useSelector(
    facilitiesSlice.selectors.activeFacilities,
  );

  return (
    <div className='flex flex-col h-72 items-center justify-center gap-0.5'>
      <BuildingOfficeIcon className='w-12 h-12 text-green-base mb-4' />
      <div className='text-sm text-black'>Select a Facility</div>
      <div className='text-xs leading-5 text-gray-400'>
        Select the location where the inspection with be conducted.
      </div>
      <Autocomplete
        className='mt-4 w-96'
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) => option.name}
        id='facility-selector'
        maxListHeight={250}
        onChange={onChange}
        options={activeFacilities}
        value={facility}
      />
    </div>
  );
};

export default FacilityChoice;
