import {
  Priority,
  SplitTaskSeriesRequest,
  TaskSeries,
  UpdateTaskSeriesRequest,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqualAsRRule } from 'components/recurrence/rrule';
import { WritableDraft } from 'immer';
import { unassignedUser } from 'utils/user';

/**
 * State specific to the editing and splitting of a task series.
 */
export type TaskSeriesEditState = {
  assigneeId: string;
  description: string;
  hasUnsavedChanges: boolean;
  /**
   * The date of the task instance being edited. Used to
   * set the asOfDate when splitting a task series.
   */
  instanceDate: string;
  originalState?: OriginalState;
  priority: Priority;
  recurrenceRule: string;
  startDate: string;
  title: string;
};

type OriginalState = Omit<
  TaskSeriesEditState,
  'hasUnsavedChanges' | 'instanceDate' | 'originalState'
>;

const initialState: TaskSeriesEditState = {
  assigneeId: '',
  description: '',
  hasUnsavedChanges: false,
  instanceDate: '',
  originalState: undefined,
  priority: Priority.Medium,
  recurrenceRule: 'FREQ=DAILY;INTERVAL=1;COUNT=1',
  startDate: LocalDate.now().toString(),
  title: '',
};

export const taskSeriesEditSlice = createSlice({
  initialState,
  name: 'taskSeriesEdit',
  reducers: {
    clear: (state) => Object.assign(state, initialState),
    load: (
      state: WritableDraft<TaskSeriesEditState>,
      action: PayloadAction<{ instanceDate: string; task: TaskSeries }>,
    ) => {
      const { instanceDate, task } = action.payload;
      state.assigneeId = task.assigneeId ?? '';
      state.description = task.description ?? '';
      state.hasUnsavedChanges = false;
      state.instanceDate = instanceDate;
      state.priority = task.priority;
      /**
       * Some tasks have an invalid recurrence rule in the backend that was
       * created with the `RRULE:` prefix. We need to remove this when reading
       * the rule. Rules created correctly are not affected by this, as they
       * don't have that substring present.
       *
       * If the backend performs a cleanup of these values, we should remove
       * the call to `replace` below.
       *
       * See ARTEMIS-3701 for details.
       */
      state.recurrenceRule = task.recurrenceRule.replace('RRULE:', '');
      state.startDate = task.startDate;
      state.title = task.title;
      /**
       * Store the original state of the task series to compare changes later.
       */
      state.originalState = {
        assigneeId: state.assigneeId,
        description: state.description,
        priority: state.priority,
        recurrenceRule: state.recurrenceRule,
        startDate: state.startDate,
        title: state.title,
      };
    },
    setTaskField: <K extends keyof TaskSeriesEditState>(
      state: WritableDraft<TaskSeriesEditState>,
      action: PayloadAction<{ field: K; value: TaskSeriesEditState[K] }>,
    ) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
  },
  selectors: {
    assigneeId: (state) => state.assigneeId,
    canSave: (state) => !!state.title,
    description: (state) => state.description,
    /**
     * Build a `UpdateTaskSeriesRequest` object from the current state. It does
     * not perform validation, it is assumed that validation is done before
     * calling this selector.
     */
    editTaskSeriesRequest: createSelector(
      [
        (state: TaskSeriesEditState) =>
          state.assigneeId === unassignedUser.id
            ? undefined
            : state.assigneeId || undefined,
        (state: TaskSeriesEditState) => state.description || undefined,
        (state: TaskSeriesEditState) => state.priority,
        (state: TaskSeriesEditState) => state.recurrenceRule,
        (state: TaskSeriesEditState) => state.startDate,
        (state: TaskSeriesEditState) => state.title,
      ],
      (assigneeId, description, priority, recurrenceRule, startDate, title) =>
        ({
          assigneeId,
          description,
          priority,
          recurrenceRule,
          startDate,
          title,
        }) as UpdateTaskSeriesRequest,
    ),
    hasUnsavedChanges: (state: TaskSeriesEditState) => {
      /**
       * Check if the current state differs from the original or initial state.
       * Returns true if there are unsaved changes, otherwise false.
       */
      return !isStateEqual(state, state.originalState ?? initialState);
    },
    instanceDate: (state) => state.instanceDate,
    priority: (state) => state.priority,
    recurrenceRule: (state) => state.recurrenceRule,
    /**
     * Build a `SplitTaskSeriesRequest` object from the current state. It does
     * not perform validation, it is assumed that validation is done before
     * calling this selector.
     */
    splitTaskSeriesRequest: createSelector(
      [
        (state: TaskSeriesEditState) =>
          state.assigneeId === unassignedUser.id
            ? undefined
            : state.assigneeId || undefined,
        (state: TaskSeriesEditState) => state.description || undefined,
        (state: TaskSeriesEditState) => state.priority,
        (state: TaskSeriesEditState) => state.recurrenceRule,
        (state: TaskSeriesEditState) => state.startDate,
        (state: TaskSeriesEditState) => state.title,
        (state: TaskSeriesEditState) => state.instanceDate,
      ],
      (
        assigneeId,
        description,
        priority,
        recurrenceRule,
        startDate,
        title,
        asOfDate,
      ) =>
        ({
          asOfDate,
          assigneeId,
          description,
          priority,
          recurrenceRule,
          startDate,
          title,
        }) as SplitTaskSeriesRequest,
    ),
    startDate: (state) => state.startDate,
    title: (state) => state.title,
  },
});

/**
 * Determine if the current state is equal to the original state of the task
 * series being edited
 */
const isStateEqual = (
  currentState: TaskSeriesEditState,
  originalState: OriginalState,
): boolean =>
  currentState.assigneeId === originalState.assigneeId &&
  currentState.startDate === originalState.startDate &&
  currentState.description === originalState.description &&
  currentState.priority === originalState.priority &&
  isEqualAsRRule(currentState.recurrenceRule, originalState.recurrenceRule) &&
  currentState.title === originalState.title;
