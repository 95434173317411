import { clsx } from 'clsx';
import { ReactNode } from 'react';

const TabNavigation = ({ children }: { children?: ReactNode }) => {
  return (
    <nav
      className={clsx(
        'flex justify-start gap-2 overflow-x-auto',
        'px-4 sm:px-8 pt-4 pb-0',
        'border-b border-gray-200',
        '[&>a]:py-4',
        '[&>a]:group [&>a]:inline-flex [&>a]:items-start',
        '[&>a]:px-1 [&>a]:text-sm [&>a]:font-medium',
        '[&>a]:whitespace-nowrap',
        '[&>a.active]:text-white [&>a.active]:bg-green-base',
        '[&>a]:rounded-t-md [&>a]:px-4',
        '[&>a.active:hover]:bg-green-dark',
        '[&>a:not(.active)]:text-gray-500',
        '[&>a:not(.active):hover]:text-gray-800',
        '[&>a:not(.active):hover]:bg-gray-200',
      )}
    >
      {children}
    </nav>
  );
};

export default TabNavigation;
