import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { FC } from 'react';

type NavButtonProps = {
  direction: 'left' | 'right';
  onClick: () => void;
  /** Only relevant on mobile view. */
  visible: boolean;
};

export const NavButton: FC<NavButtonProps> = ({
  direction,
  onClick,
  visible,
}) => (
  <button
    className={clsx(
      'p-4 sm:rounded flex-none sm:hover:bg-gray-500 sm:focus:bg-gray-500',
      'sm:active:bg-gray-100 sm:active:text-gray-500',
      { 'right-0': direction === 'right' },
      'max-sm:p-1 max-sm:absolute max-sm:top-1/2 max-sm:h-24',
      'max-sm:bg-black/50 max-sm:text-white',
      'max-sm:-translate-y-6 max-sm:z-10 max-sm:transition-opacity',
      'max-sm:duration-500',
      visible ? 'max-sm:opacity-100' : 'max-sm:opacity-0',
      { 'max-sm:rounded-r': direction === 'left' },
      { 'max-sm:rounded-l': direction === 'right' },
    )}
    onClick={onClick}
  >
    {direction === 'left' ? (
      <ChevronLeftIcon className='h-12' />
    ) : (
      <ChevronRightIcon className='h-12' />
    )}
  </button>
);
