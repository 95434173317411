export enum AssignmentType {
  All = 'all',
  Inspection = 'inspections',
  Task = 'tasks',
}

export enum AssignmentStatus {
  InProgress,
  Overdue,
  Scheduled,
}
