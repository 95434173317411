import {
  Inspection,
  InspectionStatus,
  Priority,
} from '@dakota/platform-client';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { Dialog, Tooltip } from '@mui/material';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import { configSlice } from 'features/config/configSlice';
import { updateInspection } from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import useToast from 'hooks/useToast';
import { getPriorityLabel } from 'Pages/Tasks/types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

type Props = {
  inspection: Inspection;
};

/**
 * This component is not meant to work within the CompletedInspections table,
 * or with completed inspections in general as completed inspections cannot
 * have their priority changed.
 */
export const PrioritySelect = ({ inspection }: Props) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const canUpdateInspection = useCheckPermission(Permission.UpdateInspection);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [priority, setPriority] = useState(inspection.priority);

  const isDisabled =
    !canUpdateInspection ||
    inspection.status === InspectionStatus.Completed ||
    inspection.status === InspectionStatus.Canceled ||
    inspection.status === InspectionStatus.InProgress;

  const onSave = () => {
    setIsUpdating(true);

    const updateData = {
      baseUrl,
      currentUserId: currentUser.id,
      id: inspection.id,
      priority,
      scheduledDate: inspection.timeline.scheduledDate.toString(),
      token,
      userId: inspection.userId,
      zones: inspection.zones.map((z) => z.id),
    };
    dispatch(updateInspection(updateData))
      .unwrap()
      .then(() => {
        setSuccessMessage('Inspection priority updated successfully');
        setIsDialogOpen(false);
      })
      .catch(() => {
        setErrorMessage('Failed to update inspection priority');
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <>
      <button
        aria-label='Change Priority'
        className={clsx(
          'flex items-center gap-3 cursor-pointer text-gray-700',
          'disabled:cursor-not-allowed disabled:text-gray-300',
        )}
        disabled={isDisabled}
        onClick={() => setIsDialogOpen(true)}
      >
        <Tooltip arrow placement='top' title='Change Inspection Priority'>
          <ChevronUpDownIcon className='w-4' />
        </Tooltip>
        Change Priority
      </button>
      <Dialog onClose={() => setIsDialogOpen(false)} open={isDialogOpen}>
        <div
          className={clsx(
            'flex flex-col justify-between gap-3 p-8 transition-all',
            {
              'min-h-[360px]': isAutocompleteOpen,
            },
          )}
        >
          <div className='text-lg font-medium'>Change Inspection Priority</div>
          <div className='flex-grow'>
            <div className='flex flex-col gap-2 text-sm text-gray-500'>
              <label htmlFor='inspection-priority-selector'>Priority</label>
              <Autocomplete
                getOptionLabel={getPriorityLabel}
                id='inspection-priority-selector'
                onChange={setPriority}
                onClose={() => setIsAutocompleteOpen(false)}
                onOpen={() => setIsAutocompleteOpen(true)}
                options={[Priority.High, Priority.Medium, Priority.Low]}
                value={priority}
              />
            </div>
          </div>
          <Button
            className='self-start'
            disabled={!canUpdateInspection || inspection.priority === priority}
            loading={isUpdating}
            onClick={onSave}
          >
            {isUpdating ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </Dialog>
    </>
  );
};
