import { TaskInstance, TaskState } from '@dakota/platform-client';
import {
  ExclamationCircleIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { AttachmentCounter } from 'components/AttachmentCounter';
import Tooltip from 'components/Tooltip';
import { useTaskEdit } from 'hooks/useTaskEdit';
import useToast from 'hooks/useToast';
import { FC } from 'react';

import { StatusDropdown } from '../StatusDropdown';
import { CompleteButton } from './complete';
import { TaskDetailsButton } from './taskDetailsButton';

type TaskCardProps = {
  openTaskInstance: (task: TaskInstance) => void;
  task: TaskInstance;
};

export const TaskCard: FC<TaskCardProps> = ({ openTaskInstance, task }) => {
  const { updateStatus } = useTaskEdit();
  const { setErrorMessage, setSuccessMessage } = useToast();

  const completeTask = () => {
    updateStatus(task, TaskState.Completed)
      .then(() => setSuccessMessage('Task Successfully Completed'))
      .catch(() => setErrorMessage('Status failed to update, try again later'));
  };

  const openTask = () => openTaskInstance(task);

  return (
    <div
      aria-label='Task card'
      className={clsx(
        'border border-gray-100 rounded-md group',
        'flex max-sm:flex-col justify-between text-gray-700',
        'hover:border-gray-300 *:group-hover:border-gray-300',
      )}
    >
      <div className='flex-1 flex flex-col'>
        <div
          className={clsx(
            'flex-none min-h-12 px-4 py-2 flex items-center justify-between gap-2',
            'border-b group-hover:border-gray-300 rounded-tl-md font-bold',
            { 'rounded-tr-md': task.status === TaskState.Completed },
            'max-sm:rounded-tr-md',
            task.overdue
              ? 'bg-red-base bg-opacity-10 group-hover:bg-opacity-20'
              : 'bg-gray-50 group-hover:bg-gray-100',
          )}
        >
          <Tooltip arrow placement='bottom' title='Task'>
            <PencilSquareIcon className='w-5 h-5 max-sm:hidden' />
          </Tooltip>
          <button className='w-full text-start' onClick={openTask}>
            {task.title}
          </button>
          {task.overdue && (
            <button
              className={clsx(
                'flex-none w-32 flex items-center justify-end gap-1',
                'font-medium text-red-base',
              )}
              onClick={openTask}
            >
              <ExclamationCircleIcon className='w-5 h-5' /> Overdue
            </button>
          )}
        </div>
        <div
          className={clsx(
            'flex-1 min-h-24 p-4 max-sm:flex-col',
            'flex sm:items-center sm:justify-between gap-4',
          )}
        >
          <button className='w-full text-start' onClick={openTask}>
            <div className='text-sm font-semibold'>Description</div>
            <div className='text-balance'>{task.description}</div>
          </button>
          <StatusDropdown task={task} />
        </div>
        <div
          className={clsx(
            'flex-none px-4 py-2 flex sm:items-center sm:justify-between',
            'border-t group-hover:border-gray-300',
            'max-sm:flex-col max-sm:gap-4',
          )}
        >
          <TaskDetailsButton onClick={openTask} task={task} />
          <AttachmentCounter
            comments={task.attachments.comments}
            media={task.attachments.media}
            onClick={openTask}
          />
        </div>
      </div>
      <CompleteButton onComplete={completeTask} task={task} />
    </div>
  );
};
