import { Prompt as InspectionPrompt } from '@dakota/platform-client';
import { clsx } from 'clsx';
import { Carousel, IMedia } from 'components/Carousel';
import { configSlice } from 'features/config/configSlice';
import {
  deleteAttachment,
  updateAttachment,
} from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

import { Answers } from './Answers';
import { Attachments } from './Attachments';
import { Notes } from './Notes';
import { PromptMenu } from './PromptMenu';

type PromptProps = {
  prompt: InspectionPrompt;
  /**
   * The index of the prompt in the section.
   * This is strictly to facilitate the state update when a user
   * answers the question.
   */
  promptIndex: number;
  /**
   * The index of the section in the conduct inspection state.
   * This is strictly to facilitate the state update when a user
   * answers the question.
   */
  sectionIndex: number;
};

export const Prompt: FC<PromptProps> = ({
  prompt,
  promptIndex,
  sectionIndex,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const isAdmin = useCheckPermission(Permission.Admin);
  const isPeopleManager = useCheckPermission(Permission.ManageUsers);
  const canUpdateInspection = useCheckPermission(Permission.UpdateInspection);
  const canEdit = isAdmin || isPeopleManager || canUpdateInspection;

  const [carouselFileIndex, setCarouselFileIndex] = useState(-1);

  /** Only show files that are not a security risk in the carousel. */
  const carouselFiles = prompt.media.filter(
    (media) => !media.securityRisk && !!media.filePath,
  ) as IMedia[];

  const onEditDescription = (attachmentId: string, description: string) =>
    dispatch(
      updateAttachment({
        attachmentId,
        attachmentIndex: carouselFiles.findIndex((f) => f.id === attachmentId),
        baseUrl,
        description,
        inspectionId: prompt.inspectionId,
        promptId: prompt.id,
        promptIndex,
        sectionIndex,
        token,
      }),
    ).unwrap();

  const onDeleteAttachment = async (index: number) =>
    dispatch(
      deleteAttachment({
        attachmentId: carouselFiles[index].id,
        attachmentIndex: index,
        baseUrl,
        inspectionId: prompt.inspectionId,
        promptId: prompt.id,
        promptIndex,
        sectionIndex,
        token,
      }),
    ).unwrap();

  return (
    <div className='flex flex-col p-6 border-t border-gray-300' key={prompt.id}>
      <div className='flex flex-col sm:flex-row items-start sm:items-center gap-4 relative'>
        <div className='flex-1'>
          <p className='font-semibold text-gray-800 text-sm leading-5 whitespace-pre-wrap'>
            {prompt.question.text}
          </p>
          <div className='text-sm text-gray-500'>
            {prompt.question.citation}
          </div>
        </div>
        <div
          className={clsx(
            'flex items-center gap-1',
            'max-sm:justify-between max-sm:w-full',
          )}
        >
          <Answers {...{ prompt, promptIndex, sectionIndex }} />
          <PromptMenu {...{ prompt, promptIndex, sectionIndex }} />
        </div>
      </div>
      <Attachments prompt={prompt} showInCarousel={setCarouselFileIndex} />
      <Notes {...{ prompt, promptIndex, sectionIndex }} />
      {carouselFileIndex > -1 && carouselFiles.length > 0 && (
        <Carousel
          initialFileIndex={carouselFileIndex}
          media={carouselFiles}
          onClose={() => setCarouselFileIndex(-1)}
          onDelete={canEdit ? onDeleteAttachment : undefined}
          onEditDescription={canEdit ? onEditDescription : undefined}
        />
      )}
    </div>
  );
};
