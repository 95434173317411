import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { clsx } from 'clsx';

type NoDataProps = {
  /**
   * The vertical spacing above the no data message.
   * `sm` applies `py-8` and `lg` applies `py-24`.
   *
   * @default 'sm'
   */
  verticalSpacing?: 'lg' | 'sm';
};

const NoData = ({ verticalSpacing = 'sm' }: NoDataProps) => {
  return (
    <div
      aria-label='No data'
      className={clsx(
        'flex flex-col items-center gap-y-4',
        verticalSpacing === 'lg' ? 'py-24' : 'py-8',
      )}
    >
      <div className='flex items-center justify-center w-16 h-16 border-2 border-green-base rounded-full'>
        <MagnifyingGlassIcon className='w-8 h-8 text-green-base' />
      </div>
      <div className='text-lg font-medium text-gray-700'>
        No results for selected filter criteria.
      </div>
      <div className='text-sm text-gray-400'>
        You may want to try and adjust your filters.
      </div>
    </div>
  );
};

export default NoData;
