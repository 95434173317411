import { clsx } from 'clsx';
import { Spinner } from 'components/Spinner';
import { FC, useState } from 'react';

type Props = {
  description: string;
  filename: string;
  fullSizeUrl: string;
  onError: () => void;
  toggleNavigation: () => void;
};

export const ImageWithoutThumbnail: FC<Props> = ({
  description,
  filename,
  fullSizeUrl,
  onError,
  toggleNavigation,
}) => {
  const [isFullSizeLoaded, setIsFullSizeLoaded] = useState(false);

  return (
    <div className='h-full relative'>
      <button className='h-full cursor-default' onClick={toggleNavigation}>
        <img
          alt={description || filename}
          className={clsx(
            isFullSizeLoaded ? 'object-scale-down' : 'object-contain',
            'h-full',
          )}
          onError={onError}
          onLoad={() => setIsFullSizeLoaded(true)}
          src={fullSizeUrl}
        />
      </button>
      {!isFullSizeLoaded && (
        <div
          className={clsx(
            'absolute-center',
            'flex flex-col items-center gap-1 p-8 bg-white/90 rounded-md',
            'text-gray-800 font-base overflow-hidden whitespace-nowrap',
          )}
        >
          <Spinner />
          Loading full image...
        </div>
      )}
    </div>
  );
};
