import { Facility, FormSummary, Question } from '@dakota/platform-client';
import CustomDialog from 'components/Dialog';
import { FC, useState } from 'react';
import { useImmer } from 'use-immer';
import { noOp } from 'utils/functional';

import FormBuilder from '..';
import FacilityChoice from '../choices/facilityChoice';
import GlobalOrFacilityChoice from '../choices/globalOrFacility';
import NewOrTemplateChoice from '../choices/newOrTemplate';
import TemplateChoice from '../choices/templateChoice';
import Header from './header';
import { Step, WorkflowState } from './types';

/**
 * The stepper is the initial component for the form builder.
 * It prompts the user to select whether the form is global
 * or facility-specific, and whether to start from the question
 * library or from an existing template.
 */
const FormBuilderStepperDialog: FC<{
  onClose: () => void;
  /**
   * If present, after selecting if template is global or facility specific
   * (and choosing facility), user will be taken directly to the form builder
   * with the collection of added questions. Otherwise, after selecting whether
   * the template is global or facility specific (and choosing facility), user
   * will be prompted to select if it's a new template or based on an existing
   * one.
   */
  questions?: Question[];
}> = ({ onClose, questions }) => {
  const [workflowState, setWorkflowState] = useState<WorkflowState>({
    facility: null,
    scope: undefined,
    template: null,
    templateStrategy: undefined,
  });

  const setTemplate = (template: FormSummary | null) => {
    setWorkflowState((state) => ({ ...state, template }));
  };

  const setFacility = (facility: Facility | null) => {
    setWorkflowState((state) => ({ ...state, facility }));
  };

  const setScope = (scope: 'facility' | 'global' | undefined) => {
    setWorkflowState((state) => ({ ...state, scope }));
  };

  const setTemplateStrategy = (
    templateStrategy: 'existing' | 'new' | undefined,
  ) => {
    setWorkflowState((state) => ({ ...state, templateStrategy }));
  };

  // There are 4 possible choices the user has to make:
  // 1. Global or facility
  // 2. New or template
  // 3. Facility (if facility was selected)
  // 4. Template (if template was selected)

  const templateStep: Step = {
    component: (
      <TemplateChoice
        onChange={setTemplate}
        template={workflowState.template}
      />
    ),
    nameInStepper: () => 'Select Template',
    number: 3,
    titleInDialog: 'What template would you like to base this one on?',
    undo: noOp,
  };

  const facilityStep: Step = {
    component: (
      <FacilityChoice
        facility={workflowState.facility}
        onChange={(f) => {
          setFacility(f);
          if (questions) {
            setTemplateStrategy('new');
          } else {
            addStep(templateStrategyStep);
          }
        }}
      />
    ),
    nameInStepper: (wf, status) => {
      return status === 'completed' && wf.facility
        ? wf.facility.name
        : 'Select Facility';
    },
    number: 2,
    titleInDialog: 'Where will this template be used?',
    undo: () => setFacility(null),
  };

  const templateStrategyStep: Step = {
    component: (
      <NewOrTemplateChoice
        selectExisting={() => {
          setTemplateStrategy('existing');
          addStep(templateStep);
        }}
        selectNew={() => setTemplateStrategy('new')}
      />
    ),
    nameInStepper: () => 'How to Build',
    number: 2,
    titleInDialog: 'How would you like to start creating your template?',
    undo: () => setTemplateStrategy(undefined),
  };

  const scopeStep: Step = {
    component: (
      <GlobalOrFacilityChoice
        selectFacility={() => {
          setScope('facility');
          addStep(facilityStep);
        }}
        selectGlobal={() => {
          setScope('global');
          if (questions) {
            setTemplateStrategy('new');
          } else {
            addStep(templateStrategyStep);
          }
        }}
      />
    ),
    nameInStepper: (wf, status) => {
      if (status === 'completed' && wf.scope === 'global') {
        return 'Global';
      } else if (status === 'completed' && wf.scope === 'facility') {
        return 'Facility';
      } else {
        return 'Global or Facility Template';
      }
    },
    number: 1,
    status: 'active',
    titleInDialog: questions
      ? 'Create template from uploaded questions'
      : 'Who can see the new template?',
    undo: () => setScope(undefined),
  };

  /**
   * Steps to render in the dialog and the stepper component,
   * which renders the circle with number and name for each step
   */
  const [steps, updateSteps] = useImmer([scopeStep]);

  /**
   * Add a step updating the state of previous ones
   */
  const addStep = (newStep: Step) => {
    updateSteps((draft) => {
      draft.forEach((step) => (step.status = 'completed'));
      draft.push({ ...newStep, number: draft.length + 1, status: 'active' });
    });
  };

  /**
   * Triggers the `undo` function of each step after the given index,
   * in reverse order, removes those steps, and then and sets the status
   * of the new last step (which is exactly at `index`) to 'active'
   */
  const undoIndex = (index: number) => {
    updateSteps((draft) => {
      for (let i = draft.length - 1; i > index; i--) {
        draft.pop()?.undo();
      }
      draft[draft.length - 1].undo();
      draft[draft.length - 1].status = 'active';
    });
  };

  const resetStepperAndClose = () => {
    setScope(undefined);
    setFacility(null);
    setTemplateStrategy(undefined);
    onClose();
  };

  const formBuilderWithProps = questions ? (
    <FormBuilder
      facility={workflowState.facility ?? undefined}
      onClose={resetStepperAndClose}
      questions={questions}
    />
  ) : (
    <FormBuilder
      facility={workflowState.facility ?? undefined}
      onClose={resetStepperAndClose}
      templateSummary={workflowState.template ?? undefined}
    />
  );
  return (
    <CustomDialog
      contentHeight={
        (workflowState.scope === 'facility' && !workflowState.facility) ||
        workflowState.templateStrategy === 'existing'
          ? 'min-h-[592px]'
          : 'min-h-96'
      }
      contentWidth={'w-[850px]'}
      headerTextColor='text-gray-900'
      isOpen={true}
      onClose={resetStepperAndClose}
      preventCloseOnEscape
      subTitle={'Select an option below'}
      subTitleTextColor='text-gray-500'
      title={steps[steps.length - 1].titleInDialog}
    >
      <Header
        steps={steps}
        undoIndex={undoIndex}
        workflowState={workflowState}
      />
      {workflowState.templateStrategy === 'new' || !!workflowState.template
        ? formBuilderWithProps
        : steps[steps.length - 1].component}
    </CustomDialog>
  );
};

export default FormBuilderStepperDialog;
