import { TaskSeries } from '@dakota/platform-client';
import { configSlice } from 'features/config/configSlice';
import { splitTaskSeries, updateTaskSeries } from 'features/tasks/tasksActions';
import { taskSeriesEditSlice } from 'features/tasks/taskSeriesEditSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';

import useToast from './useToast';

/**
 * Functions to edit existing tasks series in the backend.
 */
export const useTaskSeriesEdit = (task: TaskSeries, instanceDate: string) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const hasChanges = useSelector(
    taskSeriesEditSlice.selectors.hasUnsavedChanges,
  );
  const editTaskSeriesRequest = useSelector(
    taskSeriesEditSlice.selectors.editTaskSeriesRequest,
  );

  const splitTaskSeriesRequest = useSelector(
    taskSeriesEditSlice.selectors.splitTaskSeriesRequest,
  );

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    dispatch(taskSeriesEditSlice.actions.load({ instanceDate, task }));
  }, [dispatch, task, instanceDate]);

  const saveAll = useCallback(() => {
    setIsSaving(true);

    dispatch(
      updateTaskSeries({
        baseUrl,
        body: editTaskSeriesRequest,
        id: task.id,
        token,
      }),
    )
      .unwrap()
      .then(() => {
        setSuccessMessage('Task Series updated successfully');
        navigate('/tasks');
      })
      .catch(() => setErrorMessage('Failed to update task series'))
      .finally(() => setIsSaving(false));
  }, [
    baseUrl,
    dispatch,
    editTaskSeriesRequest,
    navigate,
    setErrorMessage,
    setSuccessMessage,
    task.id,
    token,
  ]);

  const splitSeries = useCallback(() => {
    setIsSaving(true);

    dispatch(
      splitTaskSeries({
        baseUrl,
        body: splitTaskSeriesRequest,
        id: task.id,
        token,
      }),
    )
      .unwrap()
      .then(() => {
        setSuccessMessage('Task Series split successfully');
        navigate('/tasks');
      })
      .catch(() => setErrorMessage('Failed to split task series'))
      .finally(() => setIsSaving(false));
  }, [
    baseUrl,
    dispatch,
    navigate,
    setErrorMessage,
    setSuccessMessage,
    splitTaskSeriesRequest,
    task.id,
    token,
  ]);

  return {
    hasChanges,
    isSaving,
    splitSeries,
    updateSeries: saveAll,
  };
};
