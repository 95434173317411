import { Inspection, InspectionStatus } from '@dakota/platform-client';
import { GridColDef } from '@mui/x-data-grid';
import { AttachmentCounter } from 'components/AttachmentCounter';
import { AssigneeAvatar } from 'components/Avatar';
import { PriorityIcon } from 'components/PriorityIcon';
import { MuiGridWrapper } from 'components/Table/GridWrapper';
import { MobileTable } from 'components/Table/mobileTable';
import { userSlice } from 'features/user/userSlice';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useDateFormat } from 'hooks/useDateFormat';
import { useUsers } from 'hooks/useUsers';
import { getPriorityLabel } from 'Pages/Tasks/types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getHighlighter } from 'utils/highlighter';
import { getStatusLabel } from 'utils/inspectionStatusLabel';

import ActionMenu from './ActionMenu';
import { PrintAlt } from './PrintAlt';
import { ResultsBar } from './ResultsBar';
import { ZoneCell } from './ZoneCell';

interface InspectionsTableProps {
  data: Inspection[];
  loading?: boolean;
  /**
   * If provided, it will highlight the search query in a few columns.
   * @default `undefined`
   */
  searchQuery?: string;
  /**
   * If `true`, the Due Date column will be replaced with the Completed Date
   * column The Completed Date column works similarly to Due Date, but the
   * former renders inspection.timeline.endDate whereas the latter renders
   * inspection.timeline.scheduledDate
   * @default `false`
   */
  showCompletedDate?: boolean;
}

const statusDisplayMap: { [key: string]: string } = {
  canceled: 'Canceled',
  completed: 'Completed',
  inprogress: 'In Progress',
  overdue: 'Overdue',
  scheduled: 'Scheduled',
};

const getDisplayStatus = (status: string): string => {
  const lowerCaseStatus = status.toLowerCase();
  return statusDisplayMap[lowerCaseStatus];
};

const InspectionsTable: FC<InspectionsTableProps> = ({
  data,
  loading,
  searchQuery = undefined,
  showCompletedDate = false,
}) => {
  const { isMobile } = useBreakpoints();

  const currentUser = useSelector(userSlice.selectors.currentUser);

  const highlight = getHighlighter(searchQuery ?? '');

  const { getUserSummary } = useUsers();
  const navigate = useNavigate();

  const { formatBackendDate } = useDateFormat();

  const openInspection = (inspection: Inspection) => {
    navigate(`/inspections/${inspection.id}?source=${location.pathname}`);
  };

  /**
   * A user can open an inspection if the inspection is not canceled and any
   * of the following is true:
   *   - The inspection is assigned to the user
   *   - The inspection is not assigned to anyone
   *   - The inspection is completed
   */
  const canOpenInspection = (inspection: Inspection) =>
    inspection.status !== InspectionStatus.Canceled &&
    (inspection.userId === currentUser.id ||
      inspection.userId === undefined ||
      inspection.status === InspectionStatus.Completed);

  const titleColumn: GridColDef<Inspection> = {
    field: 'form.name',
    flex: 1,
    headerName: 'Inspection Title',
    minWidth: 300,
    renderCell: (params) => {
      const inspection = params.row;
      return canOpenInspection(inspection) ? (
        <Link
          className='text-green-base hover:text-green-darker'
          to={`/inspections/${inspection.id}?source=${location.pathname}`}
        >
          {highlight(inspection.form.name)}
        </Link>
      ) : (
        <>{highlight(inspection.form.name)}</>
      );
    },
  };

  const dateColumn: GridColDef<Inspection> = showCompletedDate
    ? {
        field: 'completedDate',
        headerName: 'Completed Date',
        minWidth: 130,
        renderCell: (params) => (
          <span aria-label='Completed date'>
            {formatBackendDate(params.row.timeline.endDate as string)}
          </span>
        ),
      }
    : {
        field: 'dueDate',
        headerName: 'Due Date',
        renderCell: (params) => (
          <span aria-label='Due date'>
            {formatBackendDate(params.row.timeline.scheduledDate)}
          </span>
        ),
      };

  const facilityColumn: GridColDef<Inspection> = {
    field: 'facility',
    flex: 1,
    headerName: 'Facility',
    minWidth: 120,
    renderCell: (params) => highlight(params.row.facility.name),
  };

  const zoneColumn: GridColDef<Inspection> = {
    align: 'center',
    field: 'zone',
    headerAlign: 'center',
    headerName: 'Zone',
    renderCell: (params) => (
      <ZoneCell name={params.row.form.name} zones={params.row.zones} />
    ),
  };

  const statusColumn: GridColDef<Inspection> = {
    align: 'center',
    field: 'status',
    headerAlign: 'center',
    headerName: 'Status',
    renderCell: (params) => getDisplayStatus(params.row.status),
    resizable: false,
  };

  const resultsColumn: GridColDef<Inspection> = {
    align: 'center',
    display: 'flex',
    field: 'results',
    headerAlign: 'center',
    headerName: 'Results',
    renderCell: (params) => (
      <div className='w-full flex items-center *:w-full'>
        <ResultsBar prompts={params.row.prompts} status={params.row.status} />
      </div>
    ),
    width: 100,
  };

  const metadataColumn: GridColDef<Inspection> = {
    align: 'center',
    display: 'flex',
    field: 'metadata',
    headerAlign: 'center',
    headerName: '',
    minWidth: 220,
    renderCell: (params) => {
      const inspection = params.row;
      const userId = inspection.userId;
      const { comments, media } = inspection.attachments;

      return (
        <div className='flex items-center gap-2'>
          <PriorityIcon priority={inspection.priority} />
          <AssigneeAvatar user={getUserSummary(userId)} />{' '}
          <AttachmentCounter
            comments={comments}
            media={media}
            onClick={() => openInspection(inspection)}
          />
          {inspection.status === InspectionStatus.Completed && (
            <PrintAlt inspection={inspection} />
          )}
          {inspection.status !== InspectionStatus.Completed &&
            inspection.status !== InspectionStatus.Canceled && (
              <ActionMenu inspection={inspection} />
            )}
        </div>
      );
    },
    resizable: false,
  };

  const columns = [
    titleColumn,
    dateColumn,
    facilityColumn,
    zoneColumn,
    statusColumn,
    resultsColumn,
    metadataColumn,
  ];

  const priorityColumnMobile: GridColDef<Inspection> = {
    field: 'priority',
    headerName: 'Priority',
    renderCell: (params) => getPriorityLabel(params.row.priority),
  };

  const assigneeColumnMobile: GridColDef<Inspection> = {
    field: 'assignee',
    headerName: 'Assignee',
    renderCell: (params) => {
      return getUserSummary(params.row.userId)?.name;
    },
  };

  const statusColumnMobile: GridColDef<Inspection> = {
    field: 'status',
    headerName: 'Status',
    renderCell: (params) => getStatusLabel(params.row.status),
  };

  const zoneColumnMobile: GridColDef<Inspection> = {
    field: 'zone',
    headerName: 'Zones',
    renderCell: (params) =>
      params.row.zones.length === 0 ? (
        <div className='text-gray-400 italic'>No Zones</div>
      ) : (
        <ul>
          {params.row.zones.map((zone) => (
            <li key={zone.id}>{zone.name}</li>
          ))}
        </ul>
      ),
  };

  const attachmentsColumnMobile: GridColDef<Inspection> = {
    field: 'attachments',
    headerName: 'Attachments',
    renderCell: (params) => {
      const inspection = params.row;
      return (
        <AttachmentCounter
          comments={inspection.attachments.comments}
          media={inspection.attachments.media}
          onClick={
            canOpenInspection(inspection)
              ? () => openInspection(inspection)
              : undefined
          }
        />
      );
    },
  };

  const actionsColumnMobile: GridColDef<Inspection> = {
    field: 'actions',
    headerName: 'Actions',
    renderCell: (params) => {
      const inspection = params.row;
      if (inspection.status === InspectionStatus.Completed) {
        return <PrintAlt inspection={inspection} />;
      } else if (inspection.status !== InspectionStatus.Canceled) {
        return <ActionMenu inspection={inspection} />;
      }
    },
  };

  const mobileColumns = [
    titleColumn,
    dateColumn,
    facilityColumn,
    zoneColumnMobile,
    priorityColumnMobile,
    assigneeColumnMobile,
    statusColumnMobile,
    resultsColumn,
    attachmentsColumnMobile,
    actionsColumnMobile,
  ];

  return isMobile ? (
    <MobileTable columns={mobileColumns} loading={!!loading} rows={data} />
  ) : (
    <MuiGridWrapper columns={columns} loading={!!loading} rows={data} />
  );
};

export default InspectionsTable;
