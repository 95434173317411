import { useMemo } from 'react';
import { IntlConfig } from 'react-intl';

import localizedStrings from '../lang';
import { useLocalePreference } from './useLocalePreference';

export const useIntlConfig = () => {
  const { defaultLocale, userLanguage, userLocale } = useLocalePreference();

  const messagesForLanguage = useMemo(() => {
    switch (userLanguage) {
      case 'es':
        return localizedStrings.es;
      case 'en':
      default:
        return localizedStrings.en;
    }
  }, [userLanguage]);

  const intlConfig = useMemo<IntlConfig>(
    () => ({
      defaultLocale,
      locale: userLocale,
      messages: messagesForLanguage,
    }),
    [defaultLocale, userLocale, messagesForLanguage],
  );

  return { intlConfig, userLanguage };
};
