import { TaskInstance } from '@dakota/platform-client';
import { GridColDef } from '@mui/x-data-grid';
import { clsx } from 'clsx';
import { AttachmentCounter } from 'components/AttachmentCounter';
import { AssigneeAvatar } from 'components/Avatar';
import { OverdueIndicator } from 'components/OverdueIndicator';
import { PriorityIcon } from 'components/PriorityIcon';
import { MuiGridWrapper } from 'components/Table/GridWrapper';
import { MobileTable } from 'components/Table/mobileTable';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useDateFormat } from 'hooks/useDateFormat';
import { useUsers } from 'hooks/useUsers';
import { FC } from 'react';
import { getHighlighter } from 'utils/highlighter';

import { StatusDropdown } from './StatusDropdown';
import { getPriorityLabel } from './types';

interface TasksTableProps {
  data: TaskInstance[];
  loading: boolean;
  openTaskInstance: (task: TaskInstance) => void;
  /**
   * If provided, it will highlight the search query in a few columns.
   * @default `undefined`
   */
  searchQuery?: string;
  /**
   * If `true`, show the Completed Date instead of Due Date.
   * @default `false`
   */
  showCompletedDate?: boolean;
}

const TasksTable: FC<TasksTableProps> = ({
  data,
  loading,
  openTaskInstance,
  searchQuery = undefined,
  showCompletedDate = false,
}) => {
  const { isMobile } = useBreakpoints();

  const highlight = getHighlighter(searchQuery ?? '');
  const { getUserSummary } = useUsers();
  const { formatBackendDate } = useDateFormat();

  const titleColumn: GridColDef<TaskInstance> = {
    cellClassName: 'flex-col !justify-center !items-start gap-1',
    display: 'flex',
    field: 'name',
    flex: 1,
    headerName: 'Task Title',
    minWidth: 300,
    renderCell: (params) => {
      const task = params.row;
      return (
        <>
          <button
            className={clsx(
              'text-start text-green-base hover:text-green-darker',
              'w-full truncate',
            )}
            onClick={() => openTaskInstance(task)}
          >
            {highlight(task.title)}
          </button>
          {task.overdue && <OverdueIndicator />}
        </>
      );
    },
  };

  const dateColumn: GridColDef<TaskInstance> = {
    field: 'date',
    headerName: showCompletedDate ? 'Completed Date' : 'Due Date',
    renderCell: (params) =>
      showCompletedDate
        ? formatBackendDate(params.row.timeline.endDate as string)
        : formatBackendDate(params.row.timeline.scheduledDate),
    resizable: false,
    width: showCompletedDate ? 130 : 100,
  };

  const facilityColumn: GridColDef<TaskInstance> = {
    field: 'facility',
    flex: 1,
    headerName: 'Facility',
    maxWidth: 300,
    minWidth: 150,
    renderCell: (params) => highlight(params.row.facility.name),
  };

  const zoneColumn: GridColDef<TaskInstance> = {
    field: 'zone',
    headerName: 'Zone',
    minWidth: 150,
    renderCell: (params) => highlight(params.row.zone?.name),
  };

  const statusColumn: GridColDef<TaskInstance> = {
    display: 'flex',
    field: 'status',
    headerAlign: 'center',
    headerName: 'Status',
    minWidth: 160,
    renderCell: (params) => <StatusDropdown task={params.row} />,
    resizable: false,
  };

  const metadataColumn: GridColDef<TaskInstance> = {
    align: 'center',
    display: 'flex',
    field: 'metadata',
    headerAlign: 'center',
    headerName: '',
    minWidth: 180,
    renderCell: (params) => {
      const task = params.row;
      const userId = task.assigneeId;
      const { comments, media } = task.attachments;

      return (
        <div className='flex items-center gap-2'>
          <PriorityIcon priority={task.priority} />
          <AssigneeAvatar user={getUserSummary(userId)} />{' '}
          <AttachmentCounter
            comments={comments}
            media={media}
            onClick={() => openTaskInstance(task)}
          />
        </div>
      );
    },
    resizable: false,
  };

  const columns = [
    titleColumn,
    dateColumn,
    facilityColumn,
    zoneColumn,
    statusColumn,
    metadataColumn,
  ];

  const priorityColumnMobile: GridColDef<TaskInstance> = {
    field: 'priority',
    headerName: 'Priority',
    renderCell: (params) => getPriorityLabel(params.row.priority),
  };

  const assigneeColumnMobile: GridColDef<TaskInstance> = {
    field: 'assignee',
    headerName: 'Assignee',
    renderCell: (params) => getUserSummary(params.row.assigneeId)?.name,
  };

  const attachmentsColumnMobile: GridColDef<TaskInstance> = {
    field: 'attachments',
    headerName: 'Attachments',
    renderCell: (params) => (
      <AttachmentCounter
        comments={params.row.attachments.comments}
        media={params.row.attachments.media}
        onClick={() => openTaskInstance(params.row)}
      />
    ),
  };

  const mobileColumns = [
    titleColumn,
    dateColumn,
    facilityColumn,
    zoneColumn,
    priorityColumnMobile,
    assigneeColumnMobile,
    statusColumn,
    attachmentsColumnMobile,
  ];

  return isMobile ? (
    <MobileTable columns={mobileColumns} loading={!!loading} rows={data} />
  ) : (
    <MuiGridWrapper columns={columns} loading={loading} rows={data} />
  );
};

export default TasksTable;
