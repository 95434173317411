import { MultilineInput } from 'components/MultilineInput';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useAppConfiguration } from 'hooks/useAppConfiguration';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Note: FC = () => {
  const dispatch = useAppDispatch();

  const { getConfig } = useAppConfiguration();

  const note = useSelector(taskEditSlice.selectors.note);

  return (
    <>
      <div className='font-bold'>Notes</div>
      <MultilineInput
        aria-label='Initial note'
        id='task-initial-note'
        maxLength={getConfig('NoteMaxLength')}
        onChange={(value) =>
          dispatch(taskEditSlice.actions.setTaskField({ field: 'note', value }))
        }
        placeholder='Add an optional note to your task'
        value={note}
      />
    </>
  );
};
