import {
  Inspection,
  InspectionStatus,
  Priority,
  TaskInstance,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';

/**
 * Mapping the priority values to numbers for comparison, with a higher number
 * for a higher priority.
 */
const getPriorityValue = (priority: Priority): number => {
  switch (priority) {
    case Priority.High:
      return 3;
    case Priority.Low:
      return 1;
    case Priority.Medium:
      return 2;
  }
};

export const compareInspections = (lhs: Inspection, rhs: Inspection) => {
  // Overdue first
  if (
    lhs.status === InspectionStatus.Overdue &&
    rhs.status !== InspectionStatus.Overdue
  ) {
    return -1;
  }
  if (
    lhs.status !== InspectionStatus.Overdue &&
    rhs.status === InspectionStatus.Overdue
  ) {
    return 1;
  }

  // Older due date first
  if (lhs.timeline.scheduledDate !== rhs.timeline.scheduledDate) {
    return LocalDate.parse(lhs.timeline.scheduledDate).compareTo(
      LocalDate.parse(rhs.timeline.scheduledDate),
    );
  }

  // Highest priority first
  if (lhs.priority !== rhs.priority) {
    return getPriorityValue(rhs.priority) - getPriorityValue(lhs.priority);
  }

  // Alphabetical order by template name
  const templateNameCompare = lhs.form.name.localeCompare(rhs.form.name);
  if (templateNameCompare !== 0) {
    return templateNameCompare;
  }

  // This is just to make the sorting stable
  return lhs.id.localeCompare(rhs.id);
};

export const compareTasks = (lhs: TaskInstance, rhs: TaskInstance) => {
  // Overdue first
  if (lhs.overdue && !rhs.overdue) {
    return -1;
  }
  if (!lhs.overdue && rhs.overdue) {
    return 1;
  }

  // Older due date first
  if (lhs.timeline.scheduledDate !== rhs.timeline.scheduledDate) {
    return LocalDate.parse(lhs.timeline.scheduledDate).compareTo(
      LocalDate.parse(rhs.timeline.scheduledDate),
    );
  }

  // Highest priority first
  if (lhs.priority !== rhs.priority) {
    return getPriorityValue(rhs.priority) - getPriorityValue(lhs.priority);
  }

  // Alphabetical order by title
  const titleCompare = lhs.title.localeCompare(rhs.title);
  if (titleCompare !== 0) {
    return titleCompare;
  }

  // This is just to make the sorting stable
  return lhs.id.localeCompare(rhs.id);
};

const compareInspectionAndTask = (lhs: Inspection, rhs: TaskInstance) => {
  // Overdue first
  const lhsOverdue = lhs.status === InspectionStatus.Overdue;
  if (lhsOverdue && !rhs.overdue) {
    return -1;
  }
  if (!lhsOverdue && rhs.overdue) {
    return 1;
  }

  // Older due date first
  if (lhs.timeline.scheduledDate !== rhs.timeline.scheduledDate) {
    return LocalDate.parse(lhs.timeline.scheduledDate).compareTo(
      LocalDate.parse(rhs.timeline.scheduledDate),
    );
  }

  // Highest priority first
  if (lhs.priority !== rhs.priority) {
    return getPriorityValue(rhs.priority) - getPriorityValue(lhs.priority);
  }

  // Alphabetical order by form name and title
  const titleCompare = lhs.form.name.localeCompare(rhs.title);
  if (titleCompare !== 0) {
    return titleCompare;
  }

  // This is just to make the sorting stable
  return lhs.id.localeCompare(rhs.id);
};

export const compareAssignments = (
  lhs: Inspection | TaskInstance,
  rhs: Inspection | TaskInstance,
) => {
  if (lhs instanceof Inspection && rhs instanceof Inspection) {
    return compareInspections(lhs, rhs);
  }
  if (lhs instanceof TaskInstance && rhs instanceof TaskInstance) {
    return compareTasks(lhs, rhs);
  }
  if (lhs instanceof Inspection && rhs instanceof TaskInstance) {
    return compareInspectionAndTask(lhs, rhs);
  }
  return -compareInspectionAndTask(rhs as Inspection, lhs as TaskInstance);
};
