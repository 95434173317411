import { useAuth0 } from '@auth0/auth0-react';
import { Bars4Icon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { IconButton, Menu } from '@mui/material';
import { DakotaUser, IdentityClaims } from 'auth/DakotaUser';
import { clsx } from 'clsx';
import Tooltip from 'components/Tooltip';
import { configSlice } from 'features/config/configSlice';
import {
  getScheduledInspections,
  getScheduledTasks,
} from 'features/notifications/notificationsActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useInternationalization } from 'hooks/useInternationalization';
import { useIntlConfig } from 'hooks/useIntlConfig';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { FeatureFlag } from 'utils/featureFlags';

import logo from '../../assets/Dakota-Logo.png';
import { AppNotifications } from './AppNotifications';
import { UserAvatar } from './UserAvatar';
import UserMenu from './UserMenu';

type Props = {
  toggleSidebar: () => void;
};

const Header = ({ toggleSidebar }: Props) => {
  const { t } = useInternationalization('layout.header');
  const { userLanguage } = useIntlConfig();

  const isTasksEnabled = useFeatureFlag(FeatureFlag.Tasks);

  const dispatch = useAppDispatch();
  const token = useSelector(tokenSlice.selectors.token);
  const baseUrl = useSelector(configSlice.selectors.backend);

  const location = useLocation();
  const { user } = useAuth0<DakotaUser>();
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isUserMenuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    // Fetch scheduled inspections once to know if we have to notify the user
    // about scheduled/overdue inspections in the notifications panel
    void Promise.all([
      dispatch(
        getScheduledInspections({ baseUrl, token, userId: currentUser.id }),
      ),
      isTasksEnabled
        ? dispatch(
            getScheduledTasks({ baseUrl, token, userId: currentUser.id }),
          )
        : Promise.resolve(),
    ]);
  }, [baseUrl, currentUser.id, dispatch, isTasksEnabled, token]);

  const header = useMemo(() => {
    const orgName = user?.[IdentityClaims.OrgInfo].name ?? '';

    if (location.pathname.startsWith('/dashboard')) {
      return t('title.dashboard', { orgName });
    } else if (location.pathname.startsWith('/organization')) {
      return t('title.my.organization', { orgName });
    } else if (location.pathname.startsWith('/manage')) {
      return t('title.manage.content');
    } else if (location.pathname.startsWith('/inspections')) {
      return t('title.inspections');
    } else if (location.pathname.startsWith('/tasks')) {
      return t('title.tasks');
    }
    return '';
  }, [location.pathname, t, user]);

  return (
    <div
      className={clsx(
        'bg-white border-b-2 border-b-gray-100',
        'shadow-sm flex sm:px-8 my-auto',
        'justify-between items-center h-16',
      )}
      data-testid='header'
      id='header'
    >
      <h1 className='hidden text-2xl font-bold text-gray-800 sm:block'>
        {header}
      </h1>
      <div className='flex items-center sm:hidden'>
        <button
          className='h-16 w-14 flex items-center justify-center'
          onClick={toggleSidebar}
        >
          <Bars4Icon className='text-green-base' width={24} />
        </button>
        <img alt={t('alt.dakota.logo')} className='w-32' src={logo} />
      </div>
      <div className='flex items-center gap-1'>
        <Tooltip title={t('open.documentation')}>
          <IconButton
            href={`https://support.dakotasoft.com/portal/${userLanguage}/kb/dakota-inspections`}
            target='_blank'
          >
            <QuestionMarkCircleIcon className='text-gray-500 w-7 h-7' />
          </IconButton>
        </Tooltip>
        <AppNotifications />
        <IconButton
          aria-controls={anchorEl ? 'user-menu' : undefined}
          aria-expanded={anchorEl ? 'true' : undefined}
          aria-haspopup='true'
          aria-label='User menu'
          id='user-menu-button'
          onClick={handleClick}
        >
          <UserAvatar user={currentUser} />
        </IconButton>
      </div>
      {isUserMenuOpen && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          aria-labelledby='user-menu-button'
          id='user-menu'
          onClose={() => setAnchorEl(null)}
          open={isUserMenuOpen}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <UserMenu />
        </Menu>
      )}
    </div>
  );
};
export default Header;
