import { Group, ItemType } from '@dakota/platform-client';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import Autocomplete from 'components/Autocomplete';
import { getRendererWithLibraryLogo } from 'components/Autocomplete/renderFunctions';
import Button from 'components/Button';
import Input from 'components/Input';
import SidePanel from 'components/SidePanel';
import { configSlice } from 'features/config/configSlice';
import { itemGroupsSlice } from 'features/items/itemGroupsSlice';
import { addItemType, updateItemType } from 'features/items/itemTypesActions';
import { itemTypesSlice } from 'features/items/itemTypesSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export type AddEditItemTypeProps = {
  /**
   * If present, the component will be in edit mode.
   * Otherwise it will be in add mode.
   */
  itemType?: ItemType;
  onClose: () => void;
};

const AddEditItemType: FC<AddEditItemTypeProps> = ({ itemType, onClose }) => {
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const dispatch = useAppDispatch();

  const itemTypes = useSelector(itemTypesSlice.selectors.itemTypes);
  const itemGroups = useSelector(itemGroupsSlice.selectors.itemGroups);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [name, setName] = useState(itemType?.name ?? '');
  const [itemGroup, setItemGroup] = useState<Group>(
    itemGroups.find((g) => g.id === itemType?.groupId) ?? itemGroups[0],
  );
  const [saving, setSaving] = useState(false);

  const isEdit = !!itemType;

  // To validate the form, we check that the name doesn't clash with
  // any existing item type
  let existingItemTypes = itemTypes;
  if (itemType) {
    existingItemTypes = existingItemTypes.filter((f) => f.id !== itemType.id);
  }

  const trimmedName = name.trim();
  const nameExists = existingItemTypes.find(
    (f) => f.name.toLowerCase() === trimmedName.toLowerCase(),
  );

  const nameError = nameExists
    ? 'There is already an Item Type with this name'
    : '';

  const clearFormData = useCallback(() => {
    setName(itemType?.name ?? '');
    setItemGroup(itemGroups[0]);
  }, [itemGroups, itemType?.name]);

  // Form is valid if all required fields are present and name is not already
  // taken
  const isFormValid = !!trimmedName && !nameExists;

  // Edit is valid if any field is different to its original
  const isEditValid =
    trimmedName !== itemType?.name || itemGroup.id !== itemType.groupId;

  const submitHandler = () => {
    setSaving(true);

    if (itemType) {
      const updatedItemType = {
        groupId: itemGroup.id,
        id: itemType.id,
        name: trimmedName,
      } as ItemType;

      const payload = {
        baseUrl,
        itemType: updatedItemType,
        token,
      };

      dispatch(updateItemType(payload))
        .unwrap()
        .then(() => {
          clearFormData();
          setSuccessMessage('Item type updated successfully');
        })
        .catch(() => {
          setErrorMessage('Failed to update item type');
        })
        .finally(() => {
          setSaving(false);
          onClose();
        });
    } else {
      const payload = {
        baseUrl,
        itemType: {
          groupId: itemGroup.id,
          name: trimmedName,
        } as ItemType,
        token,
      };

      dispatch(addItemType(payload))
        .unwrap()
        .then(() => {
          clearFormData();
          setSuccessMessage('Item type added successfully');
        })
        .catch(() => {
          setErrorMessage('Failed to add item type');
        })
        .finally(() => {
          setSaving(false);
          onClose();
        });
    }
  };

  const cancelHandler = () => {
    if (!itemType) {
      // Clear the form only if we are adding a new item type
      clearFormData();
    }
    onClose();
  };

  return (
    <SidePanel
      isOpen
      onClose={onClose}
      PanelTitle={
        <div className='flex text-green-base gap-2'>
          {itemType ? (
            <PencilIcon className='w-6' />
          ) : (
            <PlusIcon className='w-6' />
          )}
          <p className='text-lg font-medium'>
            {itemType ? 'Edit' : 'Add'} Item Type
          </p>
        </div>
      }
      testId='add-edit-item-type-panel'
    >
      <div className='h-full flex flex-col justify-between'>
        <div className='p-6 relative'>
          <Input
            className='w-full mt-2'
            errorExplanation={nameError}
            id='item-type-name'
            label='Item Type Name'
            onChange={(e) => setName(e.target.value)}
            required
            value={name}
          />
          <br />
          <Autocomplete
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            id='item-type-group'
            onChange={setItemGroup}
            options={itemGroups}
            outsideLabel='Select Item Group'
            renderOption={getRendererWithLibraryLogo((option) => option.name)}
            required
            value={itemGroup}
          />
        </div>
        <div className='flex gap-2.5 px-6 py-4 border-t border-gray-200'>
          <Button
            className={`bg-green-base py-2 px-4 disabled:bg-gray-400 ${
              !isFormValid ? 'cursor-not-allowed' : ''
            }`}
            data-testid='add-item-type-submit-button'
            disabled={!isFormValid || (isEdit && !isEditValid) || saving}
            loading={saving}
            onClick={submitHandler}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
          <Button className='py-2 px-4' onClick={cancelHandler} secondary>
            Cancel
          </Button>
        </div>
      </div>
    </SidePanel>
  );
};

export default AddEditItemType;
