import { TaskState } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { getStatusLabel } from 'Pages/Tasks/types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Status: FC = () => {
  const dispatch = useAppDispatch();

  const status = useSelector(taskEditSlice.selectors.status);

  const options = [
    TaskState.Scheduled,
    TaskState.InProgress,
    TaskState.Completed,
    TaskState.Canceled,
  ];

  return (
    <div className='flex items-center'>
      <label className='flex-1' htmlFor='task-status'>
        Status
      </label>
      <Autocomplete
        className='w-64'
        getOptionLabel={getStatusLabel}
        id='task-status'
        onChange={(value: TaskState) =>
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'status',
              value,
            }),
          )
        }
        options={options}
        placeholder='Status'
        value={status}
      />
    </div>
  );
};
