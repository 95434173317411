import { TaskInstance, TaskState } from '@dakota/platform-client';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Tooltip from 'components/Tooltip';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { FC } from 'react';
import { Permission } from 'utils/permissions';

type CompleteButtonProps = {
  onComplete: () => void;
  task: TaskInstance;
};

export const CompleteButton: FC<CompleteButtonProps> = ({
  onComplete,
  task,
}) => {
  const canUpdateTask = useCheckPermission(Permission.UpdateTask);
  if (task.status === TaskState.Completed) {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex-none sm:w-24 flex sm:items-center sm:justify-center',
        'sm:min-h-40 sm:border-s border-gray-100 group-hover:border-gray-300',
        'max-sm:border-t',
      )}
    >
      <Tooltip
        arrow
        title={canUpdateTask ? "Mark this task as 'Completed'" : ''}
      >
        <button
          aria-label='Complete task'
          className={clsx(
            'flex flex-col items-center justify-center w-full h-full',
            'text-gray-400 disabled:cursor-not-allowed',
            'not:disabled:hover:text-green-base not:disabled:hover:bg-green-lightest',
            'text-xs font-semibold tracking-wider rounded-e-md max-sm:py-6',
          )}
          disabled={!canUpdateTask}
          onClick={onComplete}
        >
          <CheckCircleIcon className={clsx('w-7 h-7')} />
          Complete
        </button>
      </Tooltip>
    </div>
  );
};
