import { DataGridProps, GridValidRowModel } from '@mui/x-data-grid';
import { useBreakpoints } from 'hooks/useBreakpoints';

import { MuiGridWrapper } from './GridWrapper';
import { MobileTable } from './mobileTable';

export const Table = <T extends GridValidRowModel>(props: DataGridProps<T>) => {
  const { isMobile } = useBreakpoints();

  return isMobile ? <MobileTable {...props} /> : <MuiGridWrapper {...props} />;
};
