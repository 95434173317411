import {
  CreateUserRequest,
  Facility,
  Summary,
  User,
  UserDetails,
} from '@dakota/platform-client';
import {
  InformationCircleIcon,
  PencilIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import Input from 'components/Input';
import SidePanel from 'components/SidePanel';
import Tooltip from 'components/Tooltip';
import { configSlice } from 'features/config/configSlice';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { rolesSlice } from 'features/roles/rolesSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { addUser, updateUserWithAllFields } from 'features/user/userActions';
import { userSlice } from 'features/user/userSlice';
import useToast from 'hooks/useToast';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { areDisjoint } from 'utils/functional';

export type AddEditUserProps = {
  isOpen: boolean;
  onClose: () => void;
  user?: UserDetails;
};
const AddEditUser: FC<AddEditUserProps> = ({ isOpen, onClose, user }) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const activeFacilities = useSelector(
    facilitiesSlice.selectors.activeFacilities,
  );

  const isLoadingRoles = useSelector(rolesSlice.selectors.isLoadingRoles);
  const allRoles = useSelector(rolesSlice.selectors.allRolesSummaries);
  const adminRoleId = useSelector(rolesSlice.selectors.adminRoleId);

  const currentUser = useSelector(userSlice.selectors.currentUser);
  const isEditingSelf = user?.id === currentUser.id;

  const disableRoleSelection = !!user && !user.canBeManaged;

  const { setErrorMessage, setSuccessMessage } = useToast();
  const [saving, setSaving] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [roles, setRoles] = useState<Summary[]>([]);
  const [facilities, setFacilities] = useState<Facility[]>([]);

  const rolesDescription = allRoles.map((role) => {
    return (
      <ul key={role.id}>
        <div className='font-bold'>{role.name}:</div> {role.description}
      </ul>
    );
  });

  const isAdminSelected =
    roles.find((role) => role.id === adminRoleId) !== undefined;

  let canSave = !!firstName && !!lastName && !!email && roles.length > 0;
  if (user) {
    canSave &&=
      firstName !== user.firstName ||
      lastName !== user.lastName ||
      phone !== (user.phone ?? '') ||
      employeeId !== (user.employeeId ?? '') ||
      areDisjoint(roles, user.roles) ||
      areDisjoint(facilities, user.facilities.direct);
  }

  const clearFormData = () => {
    setFirstName('');
    setLastName('');
    setPhone('');
    setEmail('');
    setRoles([]);
  };

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setPhone(user.phone ?? '');
      setEmployeeId(user.employeeId ?? '');
      setEmail(user.email);
      setRoles(
        allRoles.filter((role) => user.roles.some((ur) => ur.id === role.id)),
      );

      setFacilities(
        activeFacilities.filter((f) =>
          user.facilities.direct.some((uf) => uf.id === f.id),
        ),
      );
    }
  }, [activeFacilities, adminRoleId, allRoles, user]);

  useEffect(() => {
    if (!user && isAdminSelected) {
      setFacilities([]);
    } else if (user && isAdminSelected) {
      setFacilities(
        activeFacilities.filter((f) =>
          user.facilities.direct.some((uf) => uf.id === f.id),
        ),
      );
    }
  }, [isAdminSelected, user, activeFacilities]);

  const submitHandler = () => {
    setSaving(true);

    if (user) {
      const updatedUser = {
        email: user.email,
        employeeId,
        firstName,
        id: user.id,
        inactive: user.inactive,
        lastName,
        phone,
      } as User;

      const addedRoles = roles.filter(
        (role) => !user.roles.some((ur) => ur.id === role.id),
      );

      const removedRoles = user.roles.filter(
        (ur) => !roles.some((role) => role.id === ur.id),
      );

      const addedFacilities = facilities.filter(
        (f) => !user.facilities.direct.some((uf) => uf.id === f.id),
      );

      const removedFacilities = user.facilities.direct.filter(
        (f) => !facilities.some((selected) => selected.id === f.id),
      );

      const payload = {
        addedFacilities: isAdminSelected ? [] : addedFacilities,
        addedRoles,
        baseUrl,
        removedFacilities: isAdminSelected ? [] : removedFacilities,
        removedRoles,
        token,
        user: updatedUser,
      };

      dispatch(updateUserWithAllFields(payload))
        .unwrap()
        .then(() => {
          clearFormData();
          setSuccessMessage('User updated successfully');
          onClose();
        })
        .catch(() => {
          setErrorMessage('Failed to update user');
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      const newUser = {
        email,
        employeeId,
        facilities: facilities.map((facility) => facility.id),
        firstName,
        lastName,
        phone: phone,
        roles: roles.map((role) => role.id),
      } as CreateUserRequest;

      const payload = {
        baseUrl,
        token,
        user: newUser,
      };

      dispatch(addUser(payload))
        .unwrap()
        .then(() => {
          clearFormData();
          setSuccessMessage('User added successfully');
          onClose();
        })
        .catch(() => {
          setErrorMessage('Failed to add user');
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };
  const cancelHandler = () => {
    if (!user) {
      setFirstName('');
      setLastName('');
      setEmail('');
    }
    onClose();
  };

  return (
    <SidePanel
      data-testid='add-edit-user-panel'
      isOpen={isOpen}
      onClose={onClose}
      PanelTitle={
        <div className='flex text-green-base gap-2'>
          {user ? <PencilIcon className='w-6' /> : <PlusIcon className='w-6' />}
          <p className='text-lg font-medium'>{user ? 'Edit' : 'Add'} User</p>
        </div>
      }
    >
      <div className='h-full flex flex-col justify-between'>
        <div className='flex-1 overflow-y-auto flex flex-col gap-6 p-6'>
          <h3>
            <b>General</b>
          </h3>
          <div className='w-full flex justify-stretch gap-2'>
            <div className='flex-1'>
              <Input
                aria-label='First name'
                className='w-full pt-2'
                data-testid='first-name-input'
                label='First Name'
                onChange={(e) => setFirstName(e.target.value)}
                required
                type='text'
                value={firstName}
              />
            </div>
            <div className='flex-1'>
              <Input
                aria-label='Last name'
                className='w-full pt-2'
                data-testid='last-name-input'
                label='Last Name'
                onChange={(e) => setLastName(e.target.value)}
                required
                type='text'
                value={lastName}
              />
            </div>
          </div>
          <h3>
            <b>Contact Information</b>
          </h3>
          <Input
            aria-label='Email'
            className='w-full pt-2'
            data-testid='email-input'
            disabled={!!user}
            label='Email'
            onChange={(e) => setEmail(e.target.value)}
            required
            type='email'
            value={email}
          />
          <div className='w-full flex justify-stretch gap-2'>
            <div className='flex-1'>
              <Input
                aria-label='Phone number'
                className='w-full pt-2'
                data-testid='phone-number-input'
                label='Phone Number'
                onChange={(e) => setPhone(e.target.value)}
                type='tel'
                value={phone}
              />
            </div>
            <div className='flex-1'>
              <Input
                aria-label='Employee ID'
                className='w-full pt-2'
                data-testid='employee-id-input'
                label='Employee ID'
                onChange={(e) => setEmployeeId(e.target.value)}
                type='text'
                value={employeeId}
              />
            </div>
          </div>
          <h3>
            <b>Security</b>
          </h3>
          {!isEditingSelf && (
            <div className='flex flex-col items-end'>
              <Tooltip
                data-testid='roles-tooltip'
                title={
                  <>
                    <div className='font-bold text-base'>User Roles</div>
                    <div data-testid='roles-description'>
                      {rolesDescription}
                    </div>
                  </>
                }
              >
                <InformationCircleIcon
                  className='text-gray-500 w-5 h-5'
                  data-testid='tooltip-icon'
                />
              </Tooltip>
              <div className='w-full'>
                <Autocomplete
                  className='pt-2'
                  disabled={disableRoleSelection}
                  getOptionKey={(role) => role.id}
                  getOptionLabel={(role) => role.name}
                  id='role-selector'
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  label='User Role'
                  loading={isLoadingRoles}
                  multiple
                  onChange={(selected) =>
                    setRoles(
                      allRoles.filter((role) =>
                        selected.some(
                          (selectedRole) => selectedRole.id === role.id,
                        ),
                      ),
                    )
                  }
                  options={allRoles as Summary[]}
                  required
                  value={roles}
                />
              </div>
            </div>
          )}
          <div>
            {isAdminSelected && (
              <div
                className={clsx(
                  user ? 'mb-2' : 'mb-1',
                  'text-xs italic text-gray-500',
                )}
              >
                Administrators have access to all facilities.
                {user && user.facilities.direct.length > 0 && (
                  <div className='mt-2'>
                    Existing facility associations will remain in place.
                  </div>
                )}
              </div>
            )}
            <Autocomplete
              className='w-full pt-2'
              disabled={isAdminSelected}
              getOptionKey={(facility) => facility.id}
              getOptionLabel={(facility) => facility.name}
              id='facility-selector'
              isOptionEqualToValue={(option, value) => option.id === value.id}
              label='Facility Access'
              multiple
              onChange={(selected) =>
                setFacilities(
                  activeFacilities.filter((af) =>
                    selected.some(
                      (selectedFacility) => selectedFacility.id === af.id,
                    ),
                  ),
                )
              }
              options={activeFacilities}
              value={facilities}
            />
          </div>
        </div>
        <div className='flex-none h-16 flex gap-2.5 px-6 py-4 border-t border-gray-200'>
          <Button
            aria-label='Save'
            data-testid='add-user-submit-button'
            disabled={!canSave || saving}
            loading={saving}
            onClick={submitHandler}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
          <Button data-testid='cancel-button' onClick={cancelHandler} secondary>
            Cancel
          </Button>
        </div>
      </div>
    </SidePanel>
  );
};

export default AddEditUser;
