import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material';
import { clsx } from 'clsx';
import { FC, useEffect, useState } from 'react';

type Props = {
  /**
   * when `true`, the input is styled for dark background (media carousel).
   */
  dark?: boolean;
  /**
   * When non empty, the input is styled with a red border and the error message
   * is displayed below.
   */
  error?: string;
  /**
   * When `true`, the input is disabled and the current text is animated with a
   * pulse animation to indicate loading state.
   */
  loading?: boolean;
  /**
   * Function to call when the text in the input changes. Works directly with
   * the string value instead of the event object.
   */
  onChange: (value: string) => void;
  /**
   * Making this explicit mention here, as in the regular attributes for
   * textarea this can be a number as well as some other types that don't make
   * sense for us.
   */
  value?: string;
  /**
   * When `maxLength` is defined and there are fewer characters in the
   * text than this value, a warning will be displayed with the count of
   * remaining characters available. If `false`, no such warning is displayed.
   * @default 30
   */
  warnBelowThreshold?: false | number;
} & Omit<React.RefAttributes<HTMLTextAreaElement>, 'value'> &
  Omit<TextareaAutosizeProps, 'onChange'>;

export const MultilineInput: FC<Props> = ({
  className,
  dark,
  disabled,
  error,
  id = 'multiline-input',
  loading = false,
  maxRows = 20,
  minRows = 2,
  onChange,
  warnBelowThreshold = 30,
  ...rest
}) => {
  // We use this value in the input to prevent a `value` in the props that is
  // longer than the allowed by `maxLength`
  const [workingValue, setWorkingValue] = useState('');

  useEffect(() => {
    if (rest.maxLength !== undefined) {
      setWorkingValue((rest.value ?? '').slice(0, rest.maxLength));
    } else {
      setWorkingValue(rest.value ?? '');
    }
  }, [rest.maxLength, rest.value]);

  const remaining =
    rest.maxLength === undefined
      ? undefined
      : rest.maxLength - workingValue.length;

  return (
    <>
      <TextareaAutosize
        aria-errormessage={error ? `${id}-error-message` : undefined}
        aria-invalid={!!error}
        className={clsx(
          'w-full rounded-md border-gray-300 p-1.5 min-h-6',
          'placeholder:text-gray-400 placeholder:italic',
          'focus:outline-blue-base focus:border-none focus:ring-0',
          'text-sm disabled:text-gray-400 hover:border-gray-400',
          'aria-invalid:outline-red-base disabled:cursor-not-allowed',
          { 'animate-pulse': loading },
          dark ? 'text-white bg-black' : 'text-gray-600 bg-transparent',
          className,
        )}
        disabled={disabled || loading}
        id={id}
        maxRows={maxRows}
        minRows={minRows}
        {...rest}
        onChange={(e) => {
          onChange(e.target.value);
          setWorkingValue(e.target.value);
        }}
        value={workingValue}
      />
      {remaining !== undefined &&
        warnBelowThreshold !== false &&
        remaining < warnBelowThreshold && (
          <div className='text-xs text-red-base mt-1'>
            {remaining} characters remaining
          </div>
        )}
      {error && (
        <div className='text-sm text-red-base' id={`${id}-error-message`}>
          {error}
        </div>
      )}
    </>
  );
};
