import { convert, LocalDate } from '@js-joda/core';
import { useCallback } from 'react';

import { useLocalePreference } from './useLocalePreference';

export const useDateFormat = () => {
  const { userLocale } = useLocalePreference();

  /**
   * Convert a date string as returned by the backend
   * to a string in the user's locale.
   */
  const formatBackendDate = useCallback(
    (date: string): string => {
      return convert(LocalDate.parse(date))
        .toDate()
        .toLocaleDateString(userLocale);
    },
    [userLocale],
  );

  return { formatBackendDate };
};
