import { Facility } from '@dakota/platform-client';
import { MapIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import PermissionGuard from 'auth/PermissionGuard';
import { clsx } from 'clsx';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { DropdownMenu } from 'components/DropdownMenu';
import Toggle from 'components/Toggle';
import { configSlice } from 'features/config/configSlice';
import {
  activateFacility,
  deactivateFacility,
  deleteFacility,
} from 'features/facilities/facilitiesActions';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

import AddEditFacility from './addFacility';
import { EditZones } from './editZones';

export type FacilityMenuProps = {
  facility: Facility;
};

const FacilityMenu = ({ facility }: FacilityMenuProps) => {
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const dispatch = useAppDispatch();

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [active, setActive] = useState(!facility.inactive);
  const [processingActivation, setProcessingActivation] = useState(false);
  const [processingDeletion, setProcessingDeletion] = useState(false);
  const [isEditFacilityPanelOpen, setIsEditFacilityPanelOpen] = useState(false);
  const [isEditZonesPanelOpen, setIsEditZonesPanelOpen] = useState(false);
  const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false);

  const toggleActive = (event: boolean) => {
    const clientData = { baseUrl, token };
    setProcessingActivation(true);
    if (event) {
      dispatch(activateFacility({ ...clientData, id: facility.id }))
        .unwrap()
        .then(() => {
          setActive(true);
          setSuccessMessage('Facility activated');
        })
        .catch(() => {
          setErrorMessage('Failed to activate facility');
        })
        .finally(() => {
          setProcessingActivation(false);
        });
    } else {
      dispatch(deactivateFacility({ ...clientData, id: facility.id }))
        .unwrap()
        .then(() => {
          setActive(false);
          setSuccessMessage('Facility deactivated');
        })
        .catch(() => {
          setErrorMessage('Failed to deactivate facility');
        })
        .finally(() => {
          setProcessingActivation(false);
        });
    }
  };

  const doDeleteFacility = () => {
    setProcessingDeletion(true);
    const clientData = { baseUrl, token };
    dispatch(deleteFacility({ ...clientData, id: facility.id }))
      .unwrap()
      .then(() => {
        setSuccessMessage('Facility deleted');
      })
      .catch(() => {
        setErrorMessage('Failed to delete facility');
      })
      .finally(() => {
        setProcessingDeletion(true);
        setIsConfirmingDeletion(false);
      });
  };

  const handleEditFacilityFailure = () => {
    setErrorMessage('Failed to update facility');
  };

  const handleEditFacilitySuccess = () => {
    setSuccessMessage('Facility updated successfully');
    setIsEditFacilityPanelOpen(false);
  };

  const buttonStyle = 'flex gap-2 cursor-pointer';

  return (
    <div data-testid={`facility-menu-${facility.id}`}>
      <DropdownMenu
        buttonAriaLabel={`Menu for ${facility.name}`}
        buttonTestId='facility-menu-button'
        contentTestId='facility-contextual-menu'
      >
        <div
          className={clsx(
            'bg-white w-44 flex flex-col p-4 gap-3',
            'rounded-md shadow-md text-sm font-medium text-gray-800',
          )}
        >
          <PermissionGuard permissions={Permission.UpdateFacility}>
            <button
              className={buttonStyle}
              data-testid='edit-facility-button'
              onClick={() => setIsEditFacilityPanelOpen(true)}
            >
              <PencilIcon className='w-5' />
              Edit Facility
            </button>
            <button
              className={buttonStyle}
              data-testid='edit-zones-button'
              onClick={() => setIsEditZonesPanelOpen(true)}
            >
              <MapIcon className='w-5' />
              Edit Zones
            </button>
          </PermissionGuard>
          <PermissionGuard permissions={Permission.DeleteFacility}>
            <button
              className={buttonStyle}
              data-testid='delete-facility-button'
              disabled={processingDeletion}
              onClick={() => setIsConfirmingDeletion(true)}
            >
              <TrashIcon className='w-5 text-red-base' />
              Delete
            </button>
          </PermissionGuard>
          <PermissionGuard permissions={Permission.DeactivateFacility}>
            <Toggle
              disabled={processingActivation}
              id='toggle-facility'
              isOn={active}
              label='Active'
              onToggle={(e) => toggleActive(e)}
              showIcon={true}
            />
          </PermissionGuard>
        </div>
      </DropdownMenu>
      {isEditFacilityPanelOpen && (
        <AddEditFacility
          facility={facility}
          handleFailure={handleEditFacilityFailure}
          handleSuccess={handleEditFacilitySuccess}
          isOpen={isEditFacilityPanelOpen}
          setIsOpen={setIsEditFacilityPanelOpen}
        />
      )}
      {isEditZonesPanelOpen && (
        <EditZones
          facility={facility}
          onClose={() => setIsEditZonesPanelOpen(false)}
        />
      )}
      {isConfirmingDeletion && (
        <AcknowledgmentDialog
          acknowledgmentDisabled={processingDeletion}
          acknowledgmentText={
            'Are you sure you want to delete this Facility?' +
            ' Deleting a facility will remove all associated data, including inspection and task records, from the system. ' +
            ' This action cannot be reversed.' +
            ' If you wish to retain these records while removing the facility from view you can deactivate the facility using the toggle in the options menu.'
          }
          alertButton
          buttonText='Delete facility'
          cancelButtonText='Cancel'
          confirmationButtonSide='right'
          onAcknowledgment={doDeleteFacility}
          onCloseDialog={() => setIsConfirmingDeletion(false)}
          title={`Delete '${facility.name}'?`}
        />
      )}
    </div>
  );
};

export default FacilityMenu;
