import PermissionGuard from 'auth/PermissionGuard';
import Button from 'components/Button';
import { ReactElement } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { Permission } from 'utils/permissions';

type CreateItemButtonProps = {
  /**
   * The text to display on the button
   * Note, this is also used for the button's aria label: `buttonText` + button.
   */
  buttonText: string;
  icon?: ReactElement;
  /**
   * The link to navigate to when the button is clicked.
   * @see any use of the `Link` component from `react-router-dom`
   *
   * @example '/manage/inspection-templates'
   */
  link: To;
  /**
   * The permission required to view the button. Accepts any member of the
   * `Permission` enum.
   *
   * @example Permission.CreateInspection
   */
  permission: (typeof Permission)[keyof typeof Permission];
};

export const CreateItemButton = ({
  buttonText,
  icon,
  link,
  permission,
}: CreateItemButtonProps) => {
  const navigate = useNavigate();

  return (
    <PermissionGuard permissions={permission}>
      <Button
        aria-label={`${buttonText} button`}
        icon={icon}
        onClick={() => navigate(link)}
      >
        {buttonText}
      </Button>
    </PermissionGuard>
  );
};
